import React, { useState } from 'react'
import currency from 'currency.js'
import { Link } from 'react-router-dom'

import TextButton from '../../TextButton'
import NewUploadPaymentMethodModal from './addons/PaymentMethodModal'
import Button from '../../Button'

const FormCheckout = (props) => {
  const {
    creditItems,
    creditsTotal,
    cardItems,
    cardTotal,
    cardVatTotal,
    paymentMethods,
    chosenMethodIndex,
    setChosenMethodIndex,
    creditBalance,
    creditsToPurchase,
    setCreditsToPurchase,
    creditPurchaseError,
    isMaster,
  } = props

  const [paymentMethodModalOpen, setPaymentMethodModalOpen] = useState(false)

  const getPaymentMethodText = () => {
    const { card, nickname } = paymentMethods[chosenMethodIndex]
    const { brand, last4 } = card

    if (nickname) {
      return `${brand.toUpperCase()} ending in ${last4} (${nickname})`
    } else {
      return `${brand.toUpperCase()} ending in ${last4}`
    }
  }

  const handlePaymentMethodChange = (index) => {
    setPaymentMethodModalOpen(false)
    setChosenMethodIndex(index)
  }

  const creditDifference = creditsTotal - creditBalance

  return (
    <div className="flex flex-col space-y-4 sm:space-y-0 sm:flex-row sm:space-x-6 py-8">
      <div className="flex-[2]">
        <h3 className="text-xl font-semibold tracking-tight">
          Checkout summary
        </h3>
        <div className="text-gray-700 text-sm mt-1 space-y-2">
          <p>
            Please review the checkout summary to ensure you're happy with the
            cost of this upload.
          </p>
        </div>
      </div>
      <div className="bg-white border border-gray-200 rounded-lg shadow-sm px-6 py-5 flex-[3] space-y-4">
        <div className="-mx-6">
          <h4 className="px-6 font-medium text-sm border-b border-gray-200 pb-2">
            Credits to be deducted
          </h4>
          {creditItems.map((item) => (
            <div
              className="px-6 py-4 border-b border-gray-200 flex items-center justify-between"
              key={item.description}
            >
              <p className=" text-gray-700 text-sm">{item.description}</p>
              <p className="text-gray-700 text-sm">
                {item.amount} credit{item.amount !== 1 ? 's' : ''}
              </p>
            </div>
          ))}
          <div className="pt-3 pb-3">
            <div className="flex justify-between px-6">
              <p className="text-sm font-semibold tracking-tight">
                Total credits to be deducted
              </p>
              <p className="text-sm font-semibold tracking-tight">
                {creditsTotal} credit{creditsTotal !== 1 ? 's' : ''}
              </p>
            </div>
            {creditDifference > 0 && !creditsToPurchase && (
              <div className="mx-6 mt-3">
                <div className="bg-red-100 border border-red-200 px-4 py-2 rounded-md">
                  <p className="text-sm font-semibold tracking-tight text-red-700">
                    Insufficient credit balance
                  </p>
                  <p className="text-sm text-red-700">
                    {isMaster ? (
                      <>
                        You do not have enough credits to create this upload.
                        The total for this upload is {creditsTotal} credit
                        {creditsTotal === 1 ? '' : 's'} but you{' '}
                        {creditBalance > 0 ? (
                          <>
                            only have {creditBalance} credit
                            {creditBalance === 1 ? ' ' : 's '}
                          </>
                        ) : (
                          "don't have any credits "
                        )}
                        in your account.
                      </>
                    ) : (
                      <>
                        You do not have enough credits to create this upload.
                        Please contact your master dealer to purchase credits.
                      </>
                    )}
                  </p>
                </div>
                {isMaster && (
                  <>
                    <div className="flex flex-wrap gap-x-3 gap-y-2 mt-4">
                      <Button
                        onClick={() => setCreditsToPurchase(creditDifference)}
                        color="blue"
                        size="sm"
                      >
                        Add {creditDifference} credit
                        {creditDifference === 1 ? '' : 's'} to checkout
                      </Button>
                      <Button as={Link} to="/credits" color="white" size="sm">
                        Go to credit packages
                      </Button>
                    </div>
                    <p className="text-xs text-gray-600 mt-3">
                      Credits will be charged at your single-file rate. Credits
                      added to checkout will be shown below and will only be
                      charged once you press the "Create upload" button.
                    </p>
                  </>
                )}
              </div>
            )}
          </div>
        </div>
        <div>
          <div className="-mx-6 mt-4">
            <h4 className="px-6 font-medium text-sm border-b border-gray-200 pb-2">
              Payment to be taken via debit/credit card
            </h4>
            {cardItems.length > 0 &&
              cardItems.map((item) => (
                <div
                  className="px-6 py-4 border-b border-gray-200 flex items-center justify-between"
                  key={item.description}
                >
                  <p className=" text-gray-700 text-sm">{item.description}</p>
                  <p className="text-gray-700 text-sm">
                    GBP{' '}
                    <span className="min-w-[75px] inline-block text-right">
                      {currency(item.amount).divide(100).format()}
                    </span>
                  </p>
                </div>
              ))}
            {cardItems.length === 0 && (
              <div className="px-6 py-4 border-b border-gray-200 flex items-center justify-between">
                <p className=" text-gray-700 text-sm">No add-ons selected</p>
              </div>
            )}
            <div className="pt-3 mb-3">
              {cardTotal !== 0 && (
                <>
                  <div className="flex justify-between items-start pb-1 px-6 text-xs text-gray-600 font-medium">
                    <p>
                      Using {getPaymentMethodText()}{' '}
                      <TextButton
                        size="xs"
                        className="ml-1"
                        onClick={() => setPaymentMethodModalOpen(true)}
                      >
                        Change
                      </TextButton>
                    </p>
                    <p>
                      Subtotal{' '}
                      <span className="min-w-[75px] inline-block text-right">
                        {currency(cardTotal - cardVatTotal)
                          .divide(100)
                          .format()}
                      </span>
                    </p>
                  </div>
                  {cardVatTotal !== 0 && (
                    <div className="flex justify-end pb-1 px-6 text-xs text-gray-600 font-medium">
                      <p>
                        VAT{' '}
                        <span className="min-w-[75px] inline-block text-right">
                          {currency(cardVatTotal).divide(100).format()}
                        </span>
                      </p>
                    </div>
                  )}
                </>
              )}
              <div className="flex justify-between px-6">
                <p className="text-sm font-semibold tracking-tight">
                  Total payment to be taken
                </p>
                <p className="text-sm font-semibold tracking-tight">
                  {cardTotal === 0 ? (
                    'N/A'
                  ) : (
                    <>
                      GBP{' '}
                      <span className="min-w-[75px] inline-block text-right">
                        {currency(cardTotal).divide(100).format()}
                      </span>
                    </>
                  )}
                </p>
              </div>
            </div>
          </div>
        </div>
        {creditPurchaseError && (
          <p className="text-sm text-red-600">
            An error occurred when taking payment for your credits:{' '}
            {creditPurchaseError}
          </p>
        )}
        <p className="text-xs text-gray-600">
          By clicking “Create upload" you are agreeing to our terms and
          conditions. The amount of credits listed above will be deducted from
          your account immediately. If you have selected any add-ons we will
          also collect payment automatically using your chosen payment method.
        </p>
      </div>
      {paymentMethods && (
        <NewUploadPaymentMethodModal
          isOpen={paymentMethodModalOpen}
          setIsOpen={() => setPaymentMethodModalOpen(false)}
          chosenMethodIndex={chosenMethodIndex}
          paymentMethods={paymentMethods}
          onConfirm={handlePaymentMethodChange}
        />
      )}
    </div>
  )
}

export default FormCheckout
