import React, { useState, useContext } from 'react'
import axios from 'axios'

import AuthContext from '../../../context/AuthContext'
import Button from '../../../components/Button'
import ImageUploadModal from '../../../components/ImageUploadModal'
import useLocalStorage from '../../../hooks/useLocalStorage'

const API_URL = import.meta.env.VITE_API_URL

const DealerLogo = () => {
  const [modalOpen, setModalOpen] = useState(false)
  const [isPreparing, setIsPreparing] = useState(false)
  const [isUploading, setIsUploading] = useState(false)
  const [uploadProgress, setUploadProgress] = useState(null)
  const [error, setError] = useState(false)
  const [success, setSuccess] = useState(false)

  const [auth, setAuth] = useContext(AuthContext)
  const [getAuthToken] = useLocalStorage('authToken')
  const authToken = getAuthToken()

  const { name, logoUrl } = auth.membership.dealer

  const getBusinessInitials = () => {
    const words = name.split(' ')
    const firstInitial = words[0].charAt(0).toUpperCase()
    return words.length > 1
      ? firstInitial + words[1].charAt(0).toUpperCase()
      : firstInitial + words[0].charAt(1).toUpperCase()
  }

  const handleSave = async (canvasScaled) => {
    try {
      setIsUploading(true)
      setIsPreparing(true)

      const getRes = await axios({
        baseURL: API_URL,
        url: '/settings/logo',
        method: 'get',
        headers: { Authorization: `Bearer ${authToken}` },
      })

      setIsPreparing(false)

      canvasScaled.toBlob(async (blob) => {
        await axios({
          url: getRes.data.url,
          data: blob,
          method: 'put',
          onUploadProgress: (e) => {
            setUploadProgress(Math.round((e.loaded * 100) / e.total))
          },
        })

        const patchRes = await axios({
          baseURL: API_URL,
          url: '/settings/logo',
          method: 'patch',
          headers: { Authorization: `Bearer ${authToken}` },
          data: { key: getRes.data.key },
        })

        setSuccess(true)
        setIsUploading(false)

        setAuth({
          ...auth,
          membership: {
            ...auth.membership,
            dealer: {
              ...auth.membership.dealer,
              logoUrl: patchRes.data.url,
            },
          },
        })

        setModalOpen(false)
      })
    } catch (err) {
      setError(err)
    }
  }

  return (
    <div>
      <div className="mt-8 sm:mt-6 flex items-end">
        {!logoUrl ? (
          <>
            <span className="rounded-md bg-blue-300 h-12 w-12 text-sm flex items-center justify-center font-bold mr-4">
              {getBusinessInitials()}
            </span>
            <Button color="white" size="sm" onClick={() => setModalOpen(true)}>
              Add your logo
            </Button>
          </>
        ) : (
          <>
            <img
              className="bg-blue-700 rounded-full h-12 w-12 mr-4"
              src={logoUrl}
              alt="business logo"
            />
            <Button color="white" size="sm" onClick={() => setModalOpen(true)}>
              Change logo
            </Button>
          </>
        )}
      </div>
      <p className="text-gray-600 text-sm mt-3">We recommend square images.</p>
      <ImageUploadModal
        isOpen={modalOpen}
        isUploading={isUploading}
        isPreparing={isPreparing}
        uploadProgress={uploadProgress}
        handleSave={handleSave}
        error={error}
        success={success}
        setIsOpen={() => setModalOpen(false)}
        btnText={isUploading ? 'Saving...' : 'Save logo'}
        title="Upload your logo"
      />
    </div>
  )
}

export default DealerLogo
