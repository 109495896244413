import React, { useState, useEffect, useRef, useMemo, useContext } from 'react'
import cn from 'classnames'
import { format, isToday, isYesterday } from 'date-fns'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLaughBeam } from '@fortawesome/pro-light-svg-icons'
import { faArrowUp } from '@fortawesome/pro-solid-svg-icons'
import Linkify from 'linkifyjs/react'

import AuthContext from '../context/AuthContext'
import Textarea from './Textarea'
import { EmojiPicker } from './Emoji'
import Button from './Button'

const getFormattedDate = (date) => {
  if (isToday(date)) {
    return 'Today'
  }

  if (isYesterday(date)) {
    return 'Yesterday'
  }

  return format(date, 'dd MMMM yyy')
}

const getPosition = (fromDealer, accountType) => {
  if (accountType === 'USER') {
    if (fromDealer) {
      return 'right'
    } else {
      return 'left'
    }
  }

  if (fromDealer) {
    return 'left'
  } else {
    return 'right'
  }
}

const MessageItem = (props) => {
  const { position, fromAdmin, isAdmin, message, createdAt, createdBy } = props

  const className = cn('flex flex-col max-w-[75%] flex-1 flex-shrink-0 mb-3', {
    'mr-auto justify-start': position === 'left',
    'ml-auto justify-end': position === 'right',
  })

  return (
    <div className={className}>
      <Linkify
        className={cn(
          'rounded-md shadow-sm py-1.5 pr-5 pl-3 whitespace-pre-wrap leading-5 text-sm',
          {
            'bg-gray-600 text-white': position === 'left',
            'bg-blue-600 text-white': position === 'right',
          }
        )}
        tagName="span"
        options={{ className: 'text-white underline' }}
      >
        {message}
      </Linkify>
      <div
        className={cn('flex text-xs mt-1', {
          'flex-row-reverse': position === 'right',
        })}
      >
        <p>{format(createdAt, 'hh:mma')}</p>
        <p className="mx-1 text-gray-500 mb-0.5">•</p>
        {fromAdmin && !isAdmin ? (
          <p>Sent by Topgear Support</p>
        ) : (
          <p>
            Sent by {createdBy.user.firstName} {createdBy.user.lastName}
          </p>
        )}
      </div>
    </div>
  )
}

export const Messages = (props) => {
  const {
    currentMessage,
    setCurrentMessage,
    messages = [],
    onMessageSend,
    isMessageLoading,
    isDisabled,
    isDisabledMessage,
  } = props

  const [auth] = useContext(AuthContext)
  const { accountType } = auth.membership

  const [cursorPos, setCursorPos] = useState(null)
  const textareaRef = useRef(null)

  // Preserve the cursor position in the textarea.
  // We cannot do this onChange as react resets the cursor position
  useEffect(() => {
    if (cursorPos) {
      textareaRef.current.selectionStart = cursorPos.start + 2
      textareaRef.current.selectionEnd = cursorPos.end + 2
    }
  }, [cursorPos])

  const groupedMessages = useMemo(() => {
    return messages
      .map((message) => {
        return {
          ...message,
          position: getPosition(message.createdBy.dealer, accountType),
          fromAdmin: !message.createdBy.dealer,
          isAdmin: accountType !== 'USER',
          createdAt: new Date(message.createdAt),
        }
      })
      .sort((a, b) => b.createdAt - a.createdAt)
      .reduce((acc, message) => {
        const date = getFormattedDate(message.createdAt)

        const dateIndex = acc.findIndex((x) => x.date === date)

        if (dateIndex > -1) {
          const accCopy = [...acc]
          accCopy[dateIndex].messages.unshift(message)
          return accCopy
        }

        return [...acc, { date, messages: [message] }]
      }, [])
  }, [messages, accountType])

  const handleOnSubmit = (e) => {
    if (e) {
      e.preventDefault()
    }

    if (currentMessage !== '' && !isMessageLoading) {
      onMessageSend()
    }
  }

  const handleKeyPress = (e) => {
    // Enter => Send, Enter + SHIFT => New line
    if (e.which === 13 && !e.shiftKey) {
      handleOnSubmit()
      e.preventDefault()
    }
  }

  const handleEmojiOpen = () => {
    textareaRef.current.focus()
  }

  const handleEmojiSelect = (emoji) => {
    const { selectionStart, selectionEnd } = textareaRef.current

    const textareaStrParts = [
      `${currentMessage.substring(0, selectionStart)}`,
      `${emoji.native}`,
      `${currentMessage.substring(selectionEnd, currentMessage.length)}`,
    ]
    setCurrentMessage(textareaStrParts.join(''))

    textareaRef.current.focus()
    setCursorPos({ start: selectionStart, end: selectionEnd })
  }

  return (
    <div>
      <div className="message-bg">
        {groupedMessages.length > 0 ? (
          groupedMessages.map((group) => {
            return (
              <div key={group.date}>
                <div className="flex flex-col max-w-[850px] mx-auto w-full items-center px-4">
                  <span className="bg-blue-50 rounded-md shadow-sm border-2 border-blue-600 text-blue-800 my-5 text-xs px-2 py-1 text-center">
                    {group.date}
                  </span>
                  {group.messages.map((message) => (
                    <MessageItem {...message} key={message.id} />
                  ))}
                </div>
              </div>
            )
          })
        ) : (
          <div className="flex items-center flex-col">
            <span className="bg-blue-50 rounded-md shadow-sm border-2 border-blue-600 text-blue-800 my-5 text-xs px-2 py-1 text-center">
              No message history
            </span>
          </div>
        )}
      </div>
      <div className="border-t border-gray-200 px-4 py-3">
        {!isDisabled ? (
          <form
            className="sm:flex sm:items-end sm:space-x-3"
            onSubmit={handleOnSubmit}
          >
            <div className="hidden sm:block relative">
              <EmojiPicker
                delayMount
                onOpen={handleEmojiOpen}
                onSelect={handleEmojiSelect}
              >
                <Button color="white" type="button">
                  <FontAwesomeIcon
                    icon={faLaughBeam}
                    className="text-gray-600 hover:text-gray-700"
                    size="lg"
                  />
                </Button>
              </EmojiPicker>
            </div>
            <div className="flex-1">
              <Textarea
                autoFocus
                autoComplete="off"
                spellCheck
                onChange={(e) => setCurrentMessage(e.target.value)}
                onKeyPress={handleKeyPress}
                placeholder="Type a message"
                type="text"
                label="Type message"
                labelHidden
                minRows={1}
                inputRef={textareaRef}
                value={currentMessage}
              />
            </div>
            <Button
              color="blue"
              className="w-full justify-center mt-4 sm:mt-0 sm:w-auto"
              disabled={isMessageLoading || currentMessage === ''}
              type="submit"
              icon={faArrowUp}
              iconTrailing
            >
              {isMessageLoading ? 'Sending...' : 'Send'}
            </Button>
          </form>
        ) : (
          <p className="text-gray-600 text-sm">{isDisabledMessage}</p>
        )}
      </div>
    </div>
  )
}
