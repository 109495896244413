import React, { useState, useEffect, useCallback } from 'react'
import cn from 'classnames'
import { useDropzone } from 'react-dropzone'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUpload, faTimes } from '@fortawesome/pro-light-svg-icons'
import { faCheckCircle, faTimesCircle } from '@fortawesome/pro-solid-svg-icons'
import { Line } from 'rc-progress'
import filesize from 'filesize'

import Dialog from './Dialog'
import Button from './Button'
import useFileUpload from '../hooks/useFileUpload'

const FileUploadModal = (props) => {
  const { bucket, isOpen, setIsOpen, onUploadComplete } = props

  const [
    { files, allFilesUploaded },
    { startUpload, addFiles, removeFile, resetHook },
  ] = useFileUpload(bucket)

  const [isUploading, setIsUploading] = useState(false)
  const [uploadCompleted, setUploadCompleted] = useState(false)

  const handleNewAccepted = useCallback(
    (acceptedFiles) => addFiles(acceptedFiles),
    [addFiles]
  )

  const handleNewRejected = useCallback(
    (rejectedFiles) => addFiles(rejectedFiles),
    [addFiles]
  )

  const handleRemoveFile = useCallback(
    (index) => removeFile(index),
    [removeFile]
  )

  const { getRootProps, getInputProps, isDragActive, open } = useDropzone({
    onDropAccepted: handleNewAccepted,
    onDropRejected: handleNewRejected,
    multiple: true,
    maxSize: 1024 * 1024 * 25, // 25MB
    noClick: true,
    noKeyboard: true,
  })

  useEffect(() => {
    if (isOpen) {
      setUploadCompleted(false)
      resetHook()
    }
  }, [isOpen, resetHook])

  useEffect(() => {
    if (allFilesUploaded && !uploadCompleted) {
      setIsUploading(false)

      if (onUploadComplete) {
        onUploadComplete(files)
      }
      setUploadCompleted(true)
    }
  }, [files, allFilesUploaded, onUploadComplete, uploadCompleted])

  const handleUpload = () => {
    setIsUploading(true)
    startUpload()
  }

  const renderFileStatus = (file) => {
    if (file.isPreparing) {
      return <p className="text-xs text-gray-600">Preparing...</p>
    }

    if (file.isUploaded) {
      return <p className="text-xs text-gray-600">Uploaded</p>
    }

    if (file.uploadErr) {
      return <p className="text-xs text-red-600">Error uploading file</p>
    }

    if (file.isRejected) {
      return <p className="text-xs text-red-600">{file.rejectReason}</p>
    }

    if (file.uploadProgress !== null) {
      return (
        <div className="flex items-center">
          <p className="text-xs mr-1.5 text-gray-600">
            Uploading ({file.uploadProgress}%)
          </p>
          <Line
            className="block h-2 w-[100px]"
            percent={file.uploadProgress}
            strokeWidth={6}
            strokeColor="#2563eb"
            trailColor="#2563eb"
            trailWidth={6}
          />
        </div>
      )
    }

    return <p className="text-xs text-gray-600">Ready to upload</p>
  }

  const renderFileIcon = (file) => {
    if (file.isAccepted) {
      if (file.isUploaded) {
        return (
          <FontAwesomeIcon
            icon={faCheckCircle}
            className="text-green-600 mt-1"
          />
        )
      }

      return (
        <FontAwesomeIcon icon={faCheckCircle} className="text-gray-400 mt-1" />
      )
    }

    return (
      <FontAwesomeIcon icon={faTimesCircle} className="text-red-600 mt-1" />
    )
  }

  const uploadCn = cn(
    'border-2 border-dashed border-gray-300 rounded-md mt-4 py-6 px-4',
    {
      'bg-gray-200': isDragActive,
    }
  )

  return (
    <Dialog
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      title="Upload files"
      buttonProps={{
        disabled: isUploading || !files.some((file) => file.isAccepted),
        onClick: handleUpload,
        color: 'blue',
        children: 'Upload files',
      }}
    >
      <p className="mt-4 text-sm text-gray-600">Maximum upload size is 25MB.</p>
      <div {...getRootProps({ className: uploadCn })}>
        <input {...getInputProps()} />
        <div className="flex items-center justify-center text-gray-500">
          <FontAwesomeIcon icon={faUpload} size="3x" />
        </div>
        <div className="flex flex-col items-center">
          <h2 className="mt-4 text-base font-medium text-center">
            {isDragActive
              ? 'Drop your files here to upload'
              : 'Drag and drop anywhere to upload'}
          </h2>
          <p className="my-2 text-sm uppercase text-gray-600 flex-grow-0">or</p>
          <Button size="sm" color="blue" onClick={open}>
            {isDragActive ? 'Dropping files...' : 'Browse your files'}
          </Button>
        </div>
      </div>
      {files.length > 0 && (
        <div className="mt-5 mb-3">
          <h5 className="text-base font-medium mb-3">File selected</h5>
          <div className="space-y-2">
            {files.map((file, index) => (
              <div
                key={file.name}
                className="flex items-center justify-between py-3 rounded-md px-4 border border-gray-200 shadow-sm"
              >
                <div className="flex items-start space-x-2">
                  {renderFileIcon(file)}
                  <div>
                    <p className="font-medium text-sm mb-0.5">
                      {file.name} ({filesize(file.size)})
                    </p>
                    {renderFileStatus(file)}
                  </div>
                </div>
                {!file.isUploaded && (
                  <button
                    className="rounded-md h-8 w-8 flex items-center justify-center hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-blue-600"
                    onClick={() => handleRemoveFile(index)}
                  >
                    <FontAwesomeIcon
                      size="1x"
                      className="text-gray-500"
                      icon={faTimes}
                    />
                  </button>
                )}
              </div>
            ))}
          </div>
        </div>
      )}
    </Dialog>
  )
}

export default FileUploadModal
