import React, { useContext } from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'

import AuthContext from '../../context/AuthContext'
import Title from '../../components/Title'
import Alert from '../../components/Alert'
import BoxNav from '../../components/BoxNav'
import Dealer from './dealer'
import Billing from './billing'
import Tools from './tools'
import Users from './users'
import Subdealers from './subdealers'
import Account from '../../components/settings/account'

const Settings = ({ location }) => {
  const [auth] = useContext(AuthContext)

  let boxNavLinks = [
    { to: '/settings/dealer', label: 'Dealer info' },
    { to: '/settings/billing', label: 'Billing' },
    { to: '/settings/users', label: 'Users' },
    { to: '/settings/tools', label: 'Tools' },
    { to: '/settings/account', label: 'Account' },
  ]

  const { tgtNumber, type } = auth.membership.dealer
  const isMaster = type === 'MASTER'

  if (isMaster) {
    boxNavLinks.splice(4, 0, {
      to: '/settings/subdealers',
      label: 'Subdealers',
    })
  }

  if (auth.membership.dealer.toolsConfirmationNeeded) {
    boxNavLinks = [
      { to: '/settings/billing', label: 'Billing' },
      { to: '/settings/tools', label: 'Tools' },
    ]

    return (
      <>
        <Title
          title="Account settings"
          description={`Your TGT number is ${tgtNumber}`}
        />
        <div className="max-w-5xl mx-auto px-4">
          <Alert
            error
            className="bg-white px-4 mb-5"
            title="Please confirm your tool details"
          >
            In order to gain access to our portal please confirm your tool
            details. You must provide details of at least one remapping tool
            that you own in order to access our portal. This is to protect our
            systems from malicious use and to provide you with a better service.
            If the only tool you have is not available to choose from please
            call support on 0808 901 9434. Thank you for your understanding.
          </Alert>
          <div className="bg-white rounded-lg border border-gray-200 flex flex-col min-h-[500px] shadow-sm">
            <div className="border-b border-gray-200 rounded-tr-lg rounded-tl-lg">
              <BoxNav current={location.pathname} links={boxNavLinks} />
            </div>
            <Switch>
              <Route path="/settings/tools" component={Tools} />
              <Route path="/settings/billing" component={Billing} />
              <Redirect to="/settings/tools" />
            </Switch>
          </div>
        </div>
      </>
    )
  }

  return (
    <>
      <Title
        title="Account settings"
        description={`Your TGT number is ${tgtNumber}`}
      />
      <div className="max-w-5xl mx-auto px-4">
        <div className="bg-white rounded-lg border border-gray-200 flex flex-col min-h-[500px] shadow-sm">
          <div className="border-b border-gray-200 rounded-tr-lg rounded-tl-lg">
            <BoxNav current={location.pathname} links={boxNavLinks} />
          </div>
          <Switch>
            <Route path="/settings/dealer" component={Dealer} />
            <Route path="/settings/billing" component={Billing} />
            <Route path="/settings/tools" component={Tools} />
            <Route path="/settings/users" component={Users} />
            {isMaster && (
              <Route path="/settings/subdealers" component={Subdealers} />
            )}
            <Route path="/settings/account" component={Account} />
            <Redirect to="/settings/dealer" />
          </Switch>
        </div>
      </div>
    </>
  )
}

export default Settings
