import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCoins } from '@fortawesome/pro-regular-svg-icons'
import { Link } from 'react-router-dom'

import Button from '../../Button'
import Dialog from '../../Dialog'

const NewUploadNegativeCreditsModal = ({ isOpen }) => {
  return (
    <Dialog
      isOpen={isOpen}
      title="Unable to create new upload"
      buttonProps={null}
    >
      <div className="mt-4 sm:flex sm:items-start sm:space-x-3">
        <div className="bg-blue-100 rounded-md w-10 h-10 flex flex-shrink-0 items-center justify-center">
          <FontAwesomeIcon icon={faCoins} className="text-blue-600" size="lg" />
        </div>
        <div className="mt-2 sm:mt-0">
          <h3 className="font-semibold tracking-tight mb-1">
            You have a negative credit balance
          </h3>
          <p className="text-sm text-gray-600">
            This may be because you purchased credits directly on an upload but
            a payment subsequently failed. Please purchase a credit package to
            restore your credit balance back to a postive amount.
          </p>
          <p className="text-sm text-gray-600 mt-2">
            If you purchased credits on an upload within the last few minutes
            please wait and try again.
          </p>
          <Button className="mt-4" as={Link} color="blue" to="/uploads">
            Go back to uploads
          </Button>
        </div>
      </div>
    </Dialog>
  )
}

export default NewUploadNegativeCreditsModal
