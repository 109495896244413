import React from 'react'
import cn from 'classnames'
import { Field } from 'formik'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faExclamationCircle,
  faLockAlt,
} from '@fortawesome/pro-solid-svg-icons'

import DatePicker from './DatePicker'
import AsyncSelect from './AsyncSelect'

const InputGroup = React.forwardRef((props, ref) => {
  const {
    id,
    className,
    label,
    labelHidden,
    renderLabelAction,
    type = 'text',
    renderOptions,
    icon,
    iconTrailing,
    inputClassName,
    leadingAddonBlock,
    leadingAddon,
    leadingAddonOptions,
    trailingAddon,
    trailingAddonOptions,
    trailingButton,
    hint,
    help,
    locked,
    required,
    control,
    hideErrorMessage = false,
    field = {},
    meta = {},
    form = {},
    noFormik,
    error,
    name,
    inModal,
    disabled,
    onBlur,
    onChange,
    value,
    ...rest
  } = props

  const inputError = noFormik ? error : meta.error

  const baseCn =
    'block w-full sm:text-sm sm:leading-5 text-black opacity-100 z-0'

  const errorCn = [
    'pr-10',
    'border-red-300',
    'text-red-900',
    'placeholder-red-300',
    'focus:ring-red-500',
    'focus:border-red-500',
  ]

  const inputContainerCn = cn('rounded-md shadow-sm', {
    flex: leadingAddonBlock || trailingButton,
    relative: !leadingAddonBlock && !trailingButton,
    'mt-1': !labelHidden,
  })

  const iconCn = [
    'absolute',
    'inset-y-0',
    'flex',
    'items-center',
    'pointer-events-none',
  ]

  const leadingAddonBlockCn = [
    'inline-flex',
    'items-center',
    'px-3',
    'rounded-l-md',
    'border',
    'border-r-0',
    'border-gray-300',
    'bg-gray-50',
    'text-gray-600',
    'sm:text-sm',
  ]

  const inputCn = cn(baseCn, inputClassName, {
    'border-gray-300 focus:ring-blue-500 focus:border-blue-500': !inputError,
    [errorCn.join(' ')]: inputError,
    'pl-10': (icon && !iconTrailing) || locked,
    'pr-10': icon && iconTrailing,
    'flex-1 px-3 py-2 rounded-none rounded-r-md': leadingAddonBlock,
    'rounded-md': !leadingAddonBlock,
    'bg-white': !locked && !disabled,
    'bg-gray-50': locked || disabled,
  })

  const renderLabel = () => {
    const labelCn = cn({
      'block text-sm font-medium leading-5 text-black': !labelHidden,
      'sr-only': labelHidden,
      required: required,
    })

    if (hint) {
      return (
        <div className="flex justify-between">
          <label htmlFor={id} className={labelCn}>
            {label}
          </label>
          <span className="text-sm leading-5 text-gray-600">{hint}</span>
        </div>
      )
    }

    if (renderLabelAction) {
      return (
        <div className="flex justify-between items-center">
          <label htmlFor={id} className={labelCn}>
            {label}
          </label>
          {renderLabelAction()}
        </div>
      )
    }

    return (
      <label htmlFor={id} className={labelCn}>
        {label}
      </label>
    )
  }

  const renderInput = () => {
    const {
      onChange: onChangeFormik,
      onBlur: onBlurFormik,
      ...formikField
    } = field
    const { setFieldValue } = form

    if (type === 'date') {
      return (
        <DatePicker
          id={id}
          className={inputCn}
          error={inputError}
          inModal={inModal}
          {...formikField}
          value={field.value ?? value}
          onDateChange={
            noFormik ? onChange : (date) => setFieldValue(name, date)
          }
          {...rest}
        />
      )
    } else if (type === 'select') {
      // prettier-ignore
      const handleOnChange = noFormik
        ? onChange
        : onChange
          ? (e) => onChange(e, form)
          : onChangeFormik

      return (
        <select
          id={id}
          className={inputCn}
          ref={ref}
          disabled={locked || disabled}
          {...field}
          value={field.value ?? (value || '')}
          onChange={handleOnChange}
          {...rest}
        >
          {renderOptions()}
        </select>
      )
    } else if (type === 'async-select') {
      // prettier-ignore
      const handleOnChange = noFormik
        ? onChange
        : onChange
          ? (e) => onChange(e, form)
          : onChangeFormik

      return (
        <AsyncSelect
          className="react-select-container"
          classNamePrefix="react-select"
          ref={ref}
          disabled={locked || disabled}
          {...field}
          value={field.value ?? (value || '')}
          id={id}
          onChange={handleOnChange}
          {...rest}
        />
      )
    } else {
      // prettier-ignore
      const handleOnChange = noFormik
        ? onChange
        : onChange
          ? (e) => onChange(e, form)
          : onChangeFormik

      // prettier-ignore
      const handleOnBlur = noFormik
        ? onBlur
        : onBlur
          ? () => onBlur(field.value, form)
          : onBlurFormik

      return (
        <input
          id={id}
          className={inputCn}
          type={type}
          ref={ref}
          disabled={locked || disabled}
          {...field}
          {...rest}
          value={field.value || value || ''}
          onChange={handleOnChange}
          onBlur={handleOnBlur}
        />
      )
    }
  }

  return (
    <div className={className}>
      {renderLabel()}
      <div className={inputContainerCn}>
        {icon && !iconTrailing && (
          <div className={cn(iconCn, 'left-0 pl-3')}>
            <FontAwesomeIcon
              icon={icon}
              size="1x"
              className="h-5 w-5 text-gray-400"
            />
          </div>
        )}
        {locked && (
          <div className={cn(iconCn, 'left-0 pl-3')}>
            <FontAwesomeIcon
              icon={faLockAlt}
              className="h-5 w-5 text-gray-400"
            />
          </div>
        )}
        {leadingAddonBlock && (
          <span className={cn(leadingAddonBlockCn)}>{leadingAddonBlock}</span>
        )}
        {leadingAddon && (
          <span className={cn(iconCn, 'left-0 pl-3')}>
            <span className="text-gray-600 sm:text-sm sm:leading-5">
              {leadingAddon}
            </span>
          </span>
        )}
        {renderInput()}
        {trailingAddon && (
          <span className={cn(iconCn, 'right-0 pr-3')}>
            <span className="text-gray-600 sm:text-sm sm:leading-5">
              {trailingAddon}
            </span>
          </span>
        )}
        {inputError && type !== 'date' && (
          <div className={cn(iconCn, 'right-0 pr-3')}>
            <FontAwesomeIcon
              icon={faExclamationCircle}
              className="h-5 w-5 text-red-500"
            />
          </div>
        )}
        {icon && iconTrailing && (
          <div className={cn(iconCn, 'right-0 pr-3')}>
            <FontAwesomeIcon
              icon={icon}
              size="1x"
              className="h-5 w-5 text-gray-400"
            />
          </div>
        )}
      </div>
      {inputError && !hideErrorMessage && (
        <p className="mt-2 text-sm text-red-600">{inputError}</p>
      )}
      {help && !meta.error && (
        <p className="mt-2 text-xs text-gray-600">{help}</p>
      )}
    </div>
  )
})

const InputGroupControl = React.forwardRef((props, ref) => {
  const { noFormik = true, name, ...rest } = props

  if (noFormik) {
    return <InputGroup noFormik ref={ref} name={name} {...rest} />
  } else {
    return (
      <Field name={name}>
        {(formikProps) => (
          <InputGroup ref={ref} name={name} {...rest} {...formikProps} />
        )}
      </Field>
    )
  }
})

export default InputGroupControl
