import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCoins } from '@fortawesome/pro-regular-svg-icons'
import { Link } from 'react-router-dom'

import Button from '../../Button'
import Dialog from '../../Dialog'

const NewUploadNoCreditsModal = ({ isOpen }) => {
  return (
    <Dialog
      isOpen={isOpen}
      title="Unable to create new upload"
      buttonProps={null}
    >
      <div className="mt-4 sm:flex sm:items-start sm:space-x-3">
        <div className="bg-blue-100 rounded-md w-10 h-10 flex flex-shrink-0 items-center justify-center">
          <FontAwesomeIcon icon={faCoins} className="text-blue-600" size="lg" />
        </div>
        <div className="mt-2 sm:mt-0">
          <h3 className="font-semibold tracking-tight mb-1">
            You're out of credits
          </h3>
          <p className="text-sm text-gray-600">
            You do not have any credits in your account. Please contact your
            master dealer if you should have credits available.
          </p>
          <Button className="mt-4" as={Link} color="blue" to="/uploads">
            Go back to uploads
          </Button>
        </div>
      </div>
    </Dialog>
  )
}

export default NewUploadNoCreditsModal
