import React from 'react'
import cn from 'classnames'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const Button = React.forwardRef((props, ref) => {
  const {
    children,
    block,
    disabled,
    as: Component = 'button',
    size = 'md',
    color = 'white',
    type = 'button',
    icon,
    iconClassName = '',
    iconProps = {},
    iconTrailing = false,
    className,
    ...rest
  } = props

  const buttonBaseCn = [
    'border',
    'inline-flex',
    'items-center',
    'font-medium',
    'shadow-sm',
    'appearance-none',
    'whitespace-nowrap',
    'focus:outline-none',
    'focus:ring-2',
    'focus:ring-offset-2',
  ]

  const buttonSizeCn = {
    xs: ['px-2.5', 'py-1.5', 'text-xs', 'leading-4', 'rounded'],
    sm: ['px-3', 'py-2', 'text-sm', 'leading-4', 'rounded-md'],
    md: ['px-4', 'py-2', 'text-sm', 'leading-5', 'rounded-md'],
    lg: ['px-4', 'py-2', 'text-base', 'leading-6', 'rounded-md'],
    xl: ['px-6', 'py-3', 'text-base', 'leading-6', 'rounded-md'],
  }

  const buttonColorCn = {
    white: {
      'border-gray-300': true,
      'bg-white': true,
      'text-gray-900': !disabled,
      'hover:bg-gray-50': !disabled,
      'focus:ring-blue-500': !disabled,
      'active:text-gray-800': !disabled,
      'active:bg-gray-50': !disabled,
      'text-gray-500': disabled,
    },
    blue: {
      'border-transparent': true,
      'text-white': true,
      'bg-blue-600': !disabled,
      'hover:bg-blue-500': !disabled,
      'focus:ring-blue-500': !disabled,
      'active:bg-blue-700': !disabled,
      'bg-blue-400': disabled,
    },
    'blue-invert': {
      'border-transparent': true,
      'bg-blue-100': !disabled,
      'text-blue-700': !disabled,
      'hover:bg-blue-50': !disabled,
      'focus:ring-blue-500': !disabled,
      'active:bg-blue-200': !disabled,
      'bg-blue-50': disabled,
      'text-blue-500': disabled,
    },
    red: {
      'border-transparent': true,
      'text-white': true,
      'bg-red-600': !disabled,
      'hover:bg-red-500': !disabled,
      'focus:ring-red-500': !disabled,
      'active:bg-red-700': !disabled,
      'bg-red-400': disabled,
    },
    'red-invert': {
      'border-gray-300': true,
      'bg-white': !disabled,
      'text-red-700': !disabled,
      'hover:bg-gray-50': !disabled,
      'focus:ring-red-500': !disabled,
      'active:bg-gray-50': !disabled,
      'text-red-500': disabled,
    },
  }

  const iconColorCn = {
    white: 'text-gray-500',
    blue: 'text-white',
    'blue-invert': 'text-blue-700',
    red: 'text-white',
    'red-invert': 'text-red-700',
  }

  const iconSizeCn = {
    xs: ['h-4', 'w-4'],
    sm: ['h-4', 'w-4'],
    md: ['h-5', 'w-5'],
    lg: ['h-5', 'w-5'],
    xl: ['h-5', 'w-5'],
  }

  const iconLeadingCn = {
    xs: ['-ml-0.5', 'mr-1'],
    sm: ['-ml-0.5', 'mr-2'],
    md: ['-ml-1', 'mr-2'],
    lg: ['-ml-1', 'mr-3'],
    xl: ['-ml-1', 'mr-3'],
  }

  const iconTrailingCn = {
    xs: ['ml-1', '-mr-0.5'],
    sm: ['ml-2', '-mr-0.5'],
    md: ['ml-2', '-mr-1'],
    lg: ['ml-3', '-mr-1'],
    xl: ['ml-3', '-mr-1'],
  }

  const iconCn = cn(
    iconClassName,
    iconColorCn[color],
    iconSizeCn[size],
    children && (iconTrailing ? iconTrailingCn[size] : iconLeadingCn[size])
  )

  const buttonCn = cn(
    className,
    buttonBaseCn,
    buttonSizeCn[size],
    buttonColorCn[color],
    {
      'cursor-not-allowed': disabled,
      'inline-flex': !block,
      'flex w-full justify-center': block,
    }
  )

  return (
    <Component
      className={buttonCn}
      type={type}
      disabled={disabled}
      ref={ref}
      {...rest}
    >
      {icon && !iconTrailing && (
        <FontAwesomeIcon className={iconCn} icon={icon} {...iconProps} />
      )}
      {children}
      {icon && iconTrailing && (
        <FontAwesomeIcon className={iconCn} icon={icon} {...iconProps} />
      )}
    </Component>
  )
})

export default Button
