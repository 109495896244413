import React, { useState, useEffect } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faWifiSlash } from '@fortawesome/pro-regular-svg-icons'

import usePrivateApi from '../../../../../hooks/usePrivateApi'
import Tool from './Tool'
import NewToolModal from './ModalNewTool'
import Button from '../../../../../components/Button'

const ToolList = ({ dealerId, dealer }) => {
  const [hasPaymentMethod, setHasPaymentMethod] = useState(null)
  const [tools, setTools] = useState(null)
  const [newToolModalOpen, setNewToolModalOpen] = useState(false)
  const [toolsConfirmed, setToolsConfirmed] = useState(false)

  const { toolsConfirmationNeeded } = dealer

  const [{ res, success, error, isLoading }, { sendRequest, resetSuccess }] =
    usePrivateApi()

  useEffect(() => {
    sendRequest({
      url: `/admin/dealers/${dealerId}/payment-methods/primary`,
    })
  }, [sendRequest, dealerId])

  useEffect(() => {
    if (success && hasPaymentMethod === null) {
      setHasPaymentMethod(!!res.data.paymentMethod)
      sendRequest({ url: `/admin/dealers/${dealerId}/tools` })
      resetSuccess()
    }
  }, [success, resetSuccess, res, hasPaymentMethod, sendRequest, dealerId])

  useEffect(() => {
    if (success && hasPaymentMethod !== null && tools === null) {
      setTools(res.data.tools)
      resetSuccess()
    }
  }, [hasPaymentMethod, res, success, resetSuccess, tools])

  useEffect(() => {
    if (success && tools !== null) {
      setToolsConfirmed(true)
    }
  }, [success, tools])

  const handleToolConfirmation = () => {
    sendRequest({
      url: `/admin/dealers/${dealerId}/tools/confirm`,
      method: 'post',
    })
  }

  if (error) {
    return (
      <div className="flex items-center justify-center flex-1 flex-col p-18">
        <FontAwesomeIcon
          icon={faWifiSlash}
          className="text-gray-600"
          size="2x"
        />
        <h3 className="text-xl font-semibold tracking-tighter mt-4">
          Could not retrieve tools
        </h3>
        <p className="text-sm text-gray-600 mt-1 mb-6">
          Please refresh the page to try again.
        </p>
        <Button color="white" onClick={() => window.location.reload()}>
          Refresh page
        </Button>
      </div>
    )
  }

  if (tools === null) {
    return (
      <div className="flex items-center justify-center p-12 flex-1">
        <span className="spinner" />
      </div>
    )
  }

  return (
    <div className="px-6 pt-8 pb-12 space-y-4 sm:space-y-0 sm:flex sm:space-x-8 sm:items-start">
      <div className="max-w-sm flex-1">
        <h2 className="text-lg font-semibold tracking-tight mb-2">Tools</h2>
        <div className="text-gray-600 text-sm space-y-2 max-w-prose">
          <p>
            Each remapping tool the dealer owns should be listed here, along
            with the serial number and next expiry.
          </p>
          <p>
            You can enable auto-renew on the dealer's behalf. We will email the
            dealer 7 days before payment is taken, unless the next expiry is
            within 7 days.
          </p>
        </div>
      </div>
      <div className="flex-1">
        {tools.length === 0 && (
          <div className="mt-4 mb-6 bg-gray-50 border border-gray-100 rounded-md px-4 py-3">
            <p className="text-gray-600 text-sm">
              The dealer has no tools in their account{' '}
              {toolsConfirmationNeeded ? '(not confirmed)' : '(confirmed)'}.
            </p>
          </div>
        )}
        {tools.length > 0 && (
          <>
            {toolsConfirmationNeeded && !toolsConfirmed && (
              <p className="text-sm text-red-600 mb-3">
                The dealer has not confirmed these tools.
              </p>
            )}
            <div className="space-y-3 max-w-md">
              {tools.map((tool) => (
                <Tool
                  tool={tool}
                  key={tool.id}
                  hasPaymentMethod={hasPaymentMethod}
                  dealer={dealer}
                />
              ))}
            </div>
            <Button
              color="blue"
              className="mt-4"
              onClick={() => setNewToolModalOpen(true)}
              disabled={isLoading}
            >
              Add tool
            </Button>
            {toolsConfirmationNeeded && (
              <Button
                className="mt-4 ml-3"
                onClick={handleToolConfirmation}
                disabled={(tools && tools.length < 1) || isLoading}
              >
                {isLoading ? 'Confirming...' : 'Confirm tools'}
              </Button>
            )}
          </>
        )}
      </div>
      <NewToolModal
        isOpen={newToolModalOpen}
        setIsOpen={() => setNewToolModalOpen(false)}
        dealer={dealer}
      />
    </div>
  )
}

export default ToolList
