import React, { useState, useEffect, useContext } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faWifiSlash } from '@fortawesome/pro-regular-svg-icons'

import AuthContext from '../../../context/AuthContext'
import usePrivateApi from '../../../hooks/usePrivateApi'
import Writer from './Writer'
import WriterInviteModal from './ModalInviteWriter'
import Button from '../../../components/Button'

const WriterList = () => {
  const [memberships, setMemberships] = useState(null)
  const [inviteModalOpen, setInviteModalOpen] = useState(false)
  const [auth] = useContext(AuthContext)

  const [{ res, success, error }, { sendRequest, resetSuccess }] =
    usePrivateApi()

  useEffect(() => {
    sendRequest({ url: '/admin/writers' })
  }, [sendRequest])

  useEffect(() => {
    if (success) {
      const { memberships } = res.data

      // Make sure the current user is always on top of list
      if (memberships.length > 1) {
        const index = memberships.findIndex(
          (membership) => membership.id === auth.membership.id
        )

        memberships.splice(0, 0, memberships.splice(index, 1)[0])
      }
      setMemberships(memberships)
      resetSuccess()
    }
  }, [success, resetSuccess, res, auth])

  if (error) {
    return (
      <div className="flex items-center justify-center flex-1 flex-col p-18">
        <FontAwesomeIcon
          icon={faWifiSlash}
          className="text-gray-600"
          size="2x"
        />
        <h3 className="text-xl font-semibold tracking-tighter mt-4">
          Could not retrieve writers
        </h3>
        <p className="text-sm text-gray-600 mt-1 mb-6">
          Please refresh the page to try again.
        </p>
        <Button color="white" onClick={() => window.location.reload()}>
          Refresh page
        </Button>
      </div>
    )
  }

  if (memberships === null) {
    return (
      <div className="flex items-center justify-center p-12 flex-1">
        <span className="spinner" />
      </div>
    )
  }

  return (
    <div className="px-6 pt-8 pb-12 space-y-4 sm:space-y-0 sm:flex sm:space-x-8 sm:items-start">
      <div className="max-w-sm flex-1">
        <h2 className="text-lg font-semibold tracking-tight mb-2">Writers</h2>
        <div className="text-gray-600 text-sm space-y-2 max-w-prose">
          <p>
            File writers have admin access to all uploads and support tickets.
          </p>
          <p>File writers have read-only access to vehicle data.</p>
          <p>
            File writers do not have access to dealers, credits, reports or
            system settings (except system status).
          </p>
        </div>
      </div>
      <div className="flex-1">
        <div className="space-y-3">
          {memberships.map((membership) => (
            <Writer membership={membership} auth={auth} key={membership.id} />
          ))}
        </div>
        <Button
          color="blue"
          className="mt-5"
          onClick={() => setInviteModalOpen(true)}
        >
          Invite writer
        </Button>
      </div>
      <WriterInviteModal
        isOpen={inviteModalOpen}
        setIsOpen={setInviteModalOpen}
      />
    </div>
  )
}

export default WriterList
