import React from 'react'
import TextareaAutosize from 'react-textarea-autosize'
import cn from 'classnames'
import { Field } from 'formik'

const Textarea = React.forwardRef((props, ref) => {
  const {
    id,
    label,
    labelHidden,
    required,
    renderCustomLabel,
    className,
    name,
    field,
    meta,
    noFormik,
    form,
    value,
    minRows,
    renderLabelAction,
    ...rest
  } = props

  const textareaCn = cn(
    [
      'shadow-sm',
      'block w-full',
      'border-gray-300 rounded-md',
      'sm:text-sm',
      'focus:ring-blue-500 focus:border-blue-500',
    ],
    { 'mt-1': !labelHidden },
    className
  )

  const renderLabel = () => {
    const labelCn = cn({
      'block text-sm font-medium leading-5 text-black': !labelHidden,
      'sr-only': labelHidden,
      required: required,
    })

    if (renderLabelAction) {
      return (
        <div className="flex justify-between items-center">
          <label htmlFor={id} className={labelCn}>
            {label}
          </label>
          {renderLabelAction()}
        </div>
      )
    }

    return (
      <label htmlFor={id} className={labelCn}>
        {label}
      </label>
    )
  }

  return (
    <div>
      {renderLabel()}
      <TextareaAutosize
        className={textareaCn}
        minRows={minRows || 3}
        id={id}
        ref={ref}
        name={name}
        value={value}
        {...field}
        {...rest}
      />
    </div>
  )
})

const TextareaControl = React.forwardRef((props, ref) => {
  const { noFormik = true, name, ...rest } = props

  if (noFormik) {
    return <Textarea noFormik ref={ref} name={name} {...rest} />
  } else {
    return (
      <Field name={name}>
        {(formikProps) => (
          <Textarea ref={ref} name={name} {...rest} {...formikProps} />
        )}
      </Field>
    )
  }
})

export default TextareaControl
