import React, {
  useState,
  useEffect,
  useContext,
  useMemo,
  useCallback,
} from 'react'
import isEmail from 'validator/lib/isEmail'
import { useStripe } from '@stripe/react-stripe-js'
import currency from 'currency.js'

import history from '../../../history'
import AuthContext from '../../../context/AuthContext'
import usePrivateApi from '../../../hooks/usePrivateApi'
import useFileUpload from '../../../hooks/useFileUpload'
import useObjState from '../../../hooks/useObjState'
import Title from '../../Title'
import TermsModal from '../../TermsModal'
import WarrantyModal from './WarrantyModal'
import NewUploadNoCreditsModal from './NoCreditsModal'
import NewUploadNegativeCreditsModal from './NegativeCreditsModal'
import NewUploadNoPaymentMethodsModal from './NoPaymentMethodsModal'
import NewUploadSetupError from './ErrorSetup'
import NewUploadTypeForm from './FormType'
import NewUploadVehicleForm from './FormVehicle'
import NewUploadTuneForm from './FormTune'
import NewUploadAddonForm from './addons'
import NewUploadFileUpload from './FormFiles'
import NewUploadCheckout from './FormCheckout'
import NewUploadRequiredFields from './FormRequiredFields'
import NewUploadFormActions from './FormActions'

const NewUpload = () => {
  const [auth, setAuth] = useContext(AuthContext)
  const creditBalance = auth.membership.dealer.credit.balance
  const creditBaseCost = auth.membership.dealer.credit.baseCost
  const isMaster = auth.membership.dealer.type === 'MASTER'

  const [uploadType, setUploadType] = useState('FULL')
  const [originalUpload, setOriginalUpload] = useState(null)
  // Vehicle form
  const [vehicleType, setVehicleType] = useState('CAR')
  const [vehicle, setVehicle] = useState(null)
  const [vrm, setVrm] = useState(null)
  const [reference, setReference] = useState('')

  // Mileage
  const [mileage, setMileage] = useState(null)
  const [mileageStr, setMileageStr] = useState('')

  // Tune form
  const [tuneData, setTuneData] = useObjState({
    ecuType: '',
    tuningTool: null,
    tuningToolOther: '',
    tuneRequired: 'STAGE1',
    tuneRequiredCustom: '',
  })

  // Mods form
  const [modsData, setModsData] = useObjState({
    dpf: false,
    dpfPCodes: '',
    egr: false,
    egrPCodes: '',
    o2: false,
    o2PCodes: '',
    ppf: false,
    ppfPCodes: '',
    swirlFlap: false,
    swirlFlapPCodes: '',
    hardcut: false,
    hardRpm: '',
    adblue: false,
    adbluePCodes: '',
    egt: false,
    egtPCodes: '',
    vmax: false,
    revLimiter: false,
  })

  // Addon form
  const [warrantyData, setWarrantyData] = useObjState({
    required: false,
    type: 'WARRANTY_12',
    customerName: '',
    customerEmail: '',
  })

  const [filterData, setFilterData] = useObjState({
    required: false,
    type: null,
    customerName: '',
    customerEmail: '',
    shippingAddress: {
      line1: '',
      line2: '',
      city: '',
      region: '',
      postcode: '',
      country: 'GB',
    },
  })

  const [priority, setPriority] = useState(false)
  const [paymentMethods, setPaymentMethods] = useState(null)
  const [chosenMethodIndex, setChosenMethodIndex] = useState(null)
  const [shouldChargeVat, setShouldChargeVat] = useState(null)
  const [availableAddons, setAvailableAddons] = useState([])

  // Setup
  const [hasSentFetchSetup, setHasSentFetchSetup] = useState(false)
  const [isFetchingSetup, setIsFetchingSetup] = useState(true)
  const [hasFetchedSetup, setHasFetchedSetup] = useState(false)
  const [hasFetchSetupError, setHasFetchSetupError] = useState(null)
  const [negativeCreditsModalOpen, setNegativeCreditsModalOpen] =
    useState(false)
  const [noCreditsModalOpen, setNoCreditsModalOpen] = useState(false)
  const [noPaymentMethodsModalOpen, setNoPaymentMethodsModalOpen] =
    useState(false)
  const [termsModalOpen, setTermsModalOpen] = useState(false)
  const [warrantyModalOpen, setWarrantyModalOpen] = useState(false)

  // Creating upload
  const [isCreatingUpload, setIsCreatingUpload] = useState(false)
  const [hasSentCreateUpload, setHasSentCreateUpload] = useState(false)
  const [upload, setUpload] = useState(null)

  const stripe = useStripe()

  // Ad-hoc credit purchases
  const [creditsToPurchase, setCreditsToPurchase] = useState(null)
  const [creditClientSecret, setCreditClientSecret] = useState(null)
  const [creditPurchaseError, setCreditPurchaseError] = useState(null)
  const [creditPurchaseOrderId, setCreditPurchaseOrderId] = useState(null)

  // Payment intent for addons
  const [noAddonPaymentIntentNeeded, setNoAddonPaymentIntentNeeded] =
    useState(false)
  const [addOnClientSecret, setAddonClientSecret] = useState(null)

  const [setupData, setupActions] = usePrivateApi()
  const [creditPaymentIntentData, creditPaymentIntentActions] = usePrivateApi()
  const [uploadData, uploadActions] = usePrivateApi()
  const [addOnPaymentIntentData, addOnPaymentIntentActions] = usePrivateApi()

  // UI Feedback
  const [egtDisclaimerAgreed, setEgtDisclaimerAgreed] = useState(false)
  const [showInvalid, setShowInvalid] = useState(false)

  const [
    { files, allFilesUploaded },
    { startUpload: startFileUpload, addFiles, removeFile },
  ] = useFileUpload('UPLOADS')

  // Map addons to what the server expects
  const addons = useMemo(() => {
    return availableAddons.map((addon) => {
      switch (addon.type) {
        case 'WARRANTY_12': {
          const { required, type, ...data } = warrantyData
          const selected = required && type === 'WARRANTY_12'
          addon.selected = selected
          addon.data = data
          return addon
        }

        case 'WARRANTY_24': {
          const { required, type, ...data } = warrantyData
          const selected = required && type === 'WARRANTY_24'
          addon.selected = selected
          addon.data = data
          return addon
        }

        case 'FILTERS_1': {
          const { required, type, ...data } = filterData
          const selected = required && type === 'FILTERS_1'
          addon.selected = selected
          addon.data = data
          return addon
        }

        case 'FILTERS_2': {
          const { required, type, ...data } = filterData
          const selected = required && type === 'FILTERS_2'
          addon.selected = selected
          addon.data = data
          return addon
        }

        default: {
          return addon
        }
      }
    })
  }, [availableAddons, warrantyData, filterData])

  const [creditItems, creditsTotal] = useMemo(() => {
    const getStandardCreditCost = ({ uploadType, vehicleType }) => {
      if (['TRUCK', 'TRACTOR'].includes(vehicleType)) {
        return uploadType === 'FULL' ? 3 : 1.5
      }

      if (
        vehicleType === 'CAR' &&
        uploadType === 'FULL' &&
        tuneData.tuneRequired === 'STAGE3'
      ) {
        return 3
      }

      return uploadType === 'FULL' ? 1 : 0.5
    }

    const creditItems = []
    const tuneLookup = {
      STOCK: 'Stock',
      ECO: 'Eco',
      BLEND: 'Blend',
      STAGE1: 'Stage 1',
      STAGE2: 'Stage 2',
      STAGE3: 'Stage 3',
    }

    const formattedTune = tuneLookup[tuneData.tuneRequired]
    const formattedVehicle = vehicleType.toLowerCase()
    const formattedUpload = uploadType === 'FULL' ? 'Full' : 'Adjustment'

    creditItems.push({
      amount: getStandardCreditCost({ uploadType, vehicleType }),
      description: `${formattedTune} tune for a ${formattedVehicle} (${formattedUpload} remap)`,
    })

    if (modsData.adblue) {
      creditItems.push({
        amount: 0.5,
        description: 'Adblue delete fee',
      })
    }

    if (priority) {
      creditItems.push({
        amount: 0.5,
        description: 'Priority file add-on',
      })
    }

    const creditsTotal = creditItems.reduce(
      (total, item) => total + item.amount,
      0
    )

    return [creditItems, creditsTotal]
  }, [uploadType, vehicleType, modsData, priority, tuneData])

  const [cardItems, cardTotal, cardVatTotal] = useMemo(() => {
    const cardItems = []

    if (warrantyData.required) {
      // Warranty only allowed on cars with mileage under 100,000
      if (vehicleType !== 'CAR' || mileage >= 100000) {
        setWarrantyData({ required: false })
      } else {
        // Find the selected warranty addon
        const addon = availableAddons.find(
          (addon) => addon.type === warrantyData.type
        )

        const formattedWarranty =
          warrantyData.type === 'WARRANTY_12'
            ? '12 month mechanical warranty'
            : '24 month mechanical warranty'

        // Check to see if the server has made this addon type available
        if (addon) {
          // VAT calculation based on dealer location and VAT status (from server)
          cardItems.push({
            amount: addon.cost,
            vat: shouldChargeVat ? addon.cost * 0.2 : 0,
            description: formattedWarranty,
          })
        }
      }
    }

    if (filterData.required) {
      // Filters only available on cars
      if (vehicleType !== 'CAR') {
        setFilterData({ required: false })
      } else {
        // Find the filter addon relevant to the selected vehicle
        const addon = availableAddons.find(
          (addon) => addon.type === filterData.type
        )

        const formattedFilter =
          filterData.type === 'FILTERS_1'
            ? 'High-flow TGT filter'
            : '2x High-flow TGT filter'

        // Check to see if the server has made this addon type available
        if (addon) {
          // Filters are only available to be shipped to the UK and Republic
          // of Ireland. If the dealer's address and shipping address for the
          // filter is in Ireland we don't charge VAT.
          if (!shouldChargeVat && filterData.shippingAddress.country !== 'GB') {
            cardItems.push({
              amount: addon.cost,
              vat: 0,
              description: formattedFilter,
            })
          } else {
            cardItems.push({
              amount: addon.cost,
              vat: addon.cost * 0.2,
              description: formattedFilter,
            })
          }
        }
      }
    }

    if (creditsToPurchase) {
      const amount = currency(creditBaseCost * creditsToPurchase).value
      cardItems.push({
        amount,
        vat: currency(shouldChargeVat ? amount * 0.2 : 0).value,
        description: `Purchase of ${creditsToPurchase} credit${
          creditsToPurchase === 1 ? '' : 's'
        }`,
      })
    }

    const [cardTotal, cardVatTotal] = cardItems.reduce(
      (totals, item) => {
        totals[0] = totals[0] + item.amount + item.vat
        totals[1] = totals[1] + item.vat
        return totals
      },
      [0, 0]
    )

    return [cardItems, cardTotal, cardVatTotal]
  }, [
    availableAddons,
    filterData,
    mileage,
    setWarrantyData,
    shouldChargeVat,
    warrantyData,
    setFilterData,
    vehicleType,
    creditBaseCost,
    creditsToPurchase,
  ])

  // #1: Fetch setup (payment methods, addons etc).
  // If dealer credit balance is negative, don't allow any ad-hoc purchases.
  // Show the terms modal if the dealer hasn't agreed to the latest terms
  useEffect(() => {
    if (!upload) {
      if (auth.membership.dealer.terms.requiresAcceptance) {
        setTermsModalOpen(true)
        setIsFetchingSetup(false)
      } else if (creditBalance < 0) {
        setNegativeCreditsModalOpen(true)
        setIsFetchingSetup(false)
      } else if (!isMaster && creditBalance < 1) {
        setNoCreditsModalOpen(true)
        setIsFetchingSetup(false)
      } else if (!hasSentFetchSetup) {
        setHasSentFetchSetup(true)
        setupActions.sendRequest({ url: '/billing/addons/setup' })
      }
    }
  }, [setupActions, auth, upload, hasSentFetchSetup, creditBalance, isMaster])

  // #2: Deal with setup response.
  useEffect(() => {
    if (setupData.success && !hasFetchedSetup) {
      const { paymentMethods, addons, shouldChargeVat } = setupData.res.data

      if (paymentMethods.length < 1) {
        setNoPaymentMethodsModalOpen(true)
      } else {
        // Make sure our primary payment method is the first method
        const primaryIndex = paymentMethods.findIndex(
          (method) => method.primary
        )
        paymentMethods.splice(0, 0, paymentMethods.splice(primaryIndex, 1)[0])
      }

      setPaymentMethods(paymentMethods)
      setChosenMethodIndex(0)
      setAvailableAddons(addons)
      setShouldChargeVat(shouldChargeVat)
      setHasFetchedSetup(true)
      setIsFetchingSetup(false)
    }
  }, [setupData, hasFetchedSetup])

  // #3: Purchase any ad-hoc credits the dealers needs
  useEffect(() => {
    if (creditPaymentIntentData.success && !creditClientSecret) {
      const { clientSecret, orderId } = creditPaymentIntentData.res.data
      setCreditClientSecret(clientSecret)

      const takePayment = async () => {
        const result = await stripe.confirmCardPayment(clientSecret, {
          payment_method: paymentMethods[chosenMethodIndex].id,
        })

        if (result.error) {
          setCreditPurchaseError(result.error.message)
          setIsCreatingUpload(false)
        } else {
          if (result.paymentIntent.status === 'succeeded') {
            setCreditPurchaseOrderId(orderId)
            startFileUpload()
          }
        }
      }

      takePayment()
    }
  }, [
    chosenMethodIndex,
    creditClientSecret,
    creditPaymentIntentData,
    paymentMethods,
    startFileUpload,
    stripe,
  ])

  // #4: Our files have been uploaded to remote storage. We can now create the
  // upload on our server. We need the hasSentCreateUpload flag to prevent
  // duplicate requests.
  useEffect(() => {
    if (allFilesUploaded && !hasSentCreateUpload) {
      setHasSentCreateUpload(true)

      uploadActions.sendRequest({
        url: '/uploads',
        method: 'post',
        data: {
          uploadType,
          originalUpload: originalUpload?.id,
          vehicle: vehicleType === 'CAR' ? vehicle.id : undefined,
          vehicleType,
          manualVehicle: vehicleType !== 'CAR' ? vehicle : undefined,
          reference,
          vrm,
          mileage,
          tune: tuneData,
          mods: modsData,
          addons,
          priority,
          orderId: creditPurchaseOrderId,
          files: files.map((file) => file.id),
        },
      })
    }
  }, [
    uploadType,
    originalUpload,
    vehicle,
    reference,
    vrm,
    mileage,
    tuneData,
    modsData,
    addons,
    files,
    allFilesUploaded,
    uploadActions,
    hasSentCreateUpload,
    vehicleType,
    priority,
    creditPurchaseOrderId,
  ])

  // #5: Our upload is created. If no addons have been selected, mark the
  // upload as complete. If we do require payment to taken for add-ons, fetch
  // the payment intent. We deduct the credit from the user on the client
  // side here to provide immediate feedback.
  useEffect(() => {
    if (uploadData.success && !upload) {
      setUpload(uploadData.res.data.upload)

      setAuth({
        ...auth,
        membership: {
          ...auth.membership,
          dealer: {
            ...auth.membership.dealer,
            credit: {
              ...auth.membership.dealer.credit,
              balance:
                auth.membership.dealer.credit.balance -
                creditsTotal +
                (creditsToPurchase || 0),
            },
          },
        },
      })

      if (noAddonPaymentIntentNeeded) {
        history.push({
          pathname: '/uploads/new/success',
          state: { upload: { ...uploadData.res.data.upload, vehicle } },
        })
      } else {
        addOnPaymentIntentActions.sendRequest({
          url: '/billing/addons/checkout',
          method: 'post',
          data: { uploadId: uploadData.res.data.upload.id },
        })
      }
    }
  }, [
    upload,
    uploadType,
    uploadData,
    addOnPaymentIntentActions,
    noAddonPaymentIntentNeeded,
    auth,
    setAuth,
    vehicle,
    creditsTotal,
    creditsToPurchase,
  ])

  // #5: We have our add-on payment intent - attempt to complete the charge.
  useEffect(() => {
    if (addOnPaymentIntentData.success && !addOnClientSecret) {
      const { clientSecret } = addOnPaymentIntentData.res.data
      setAddonClientSecret(clientSecret)

      const takePayment = async () => {
        const result = await stripe.confirmCardPayment(clientSecret, {
          payment_method: paymentMethods[chosenMethodIndex].id,
        })

        if (result.error) {
          history.push({
            pathname: '/uploads/new/payment-error',
            state: {
              stripeError: result.error.message,
              paymentMethods,
              chosenMethodIndex,
              addOnClientSecret,
              upload,
            },
          })
        } else {
          if (result.paymentIntent.status === 'succeeded') {
            history.push({
              pathname: '/uploads/new/success',
              state: { upload: { ...upload, vehicle } },
            })
          }
        }
      }

      takePayment()
    }
  }, [
    addOnPaymentIntentData,
    chosenMethodIndex,
    paymentMethods,
    stripe,
    addOnClientSecret,
    upload,
    vehicle,
  ])

  // #6: Handle any and all errors
  useEffect(() => {
    if (setupData.error) {
      setHasFetchSetupError(setupData.error)
      setIsFetchingSetup(false)
      setupActions.resetError()
    }

    if (uploadData.error) {
      if (uploadData.error.response) {
        history.push({
          pathname: '/uploads/new/error',
          state: { errCode: uploadData.error.response.status },
        })
      } else if (uploadData.error.request) {
        history.push({
          pathname: '/uploads/new/error',
          state: { errCode: 100 },
        })
      } else {
        history.push({
          pathname: '/uploads/new/error',
          state: { errCode: 900 },
        })
      }
    }

    if (addOnPaymentIntentData.error) {
      if (addOnPaymentIntentData.error.response) {
        history.push({
          pathname: '/uploads/new/payment-intent-error',
          state: {
            upload,
            errCode: addOnPaymentIntentData.error.response.status,
          },
        })
      } else if (addOnPaymentIntentData.error.request) {
        history.push({
          pathname: '/uploads/new/payment-intent-error',
          state: { upload, errCode: 100 },
        })
      } else {
        history.push({
          pathname: '/uploads/new/payment-intent-error',
          state: { upload, errCode: 900 },
        })
      }
    }
  }, [
    setupData,
    setupActions,
    uploadData,
    uploadActions,
    addOnPaymentIntentData,
    upload,
  ])

  // Keep credits to purchase in sync with total credit deficit
  useEffect(() => {
    if (creditsToPurchase) {
      if (creditsToPurchase !== creditsTotal - creditBalance) {
        setCreditsToPurchase(creditsTotal - creditBalance)
      }
    }
  }, [creditBalance, creditsToPurchase, creditsTotal])

  const handleTermsAccept = useCallback(() => {
    setIsFetchingSetup(true)
    setHasSentFetchSetup(true)
    setupActions.sendRequest({ url: '/billing/addons/setup' })
    setTermsModalOpen(false)
  }, [setupActions])

  const isInvalid = useMemo(() => {
    if (creditsToPurchase) {
      if (creditsToPurchase + creditBalance < creditsTotal) {
        return true
      }
    } else if (creditBalance < creditsTotal) {
      return true
    }

    if (uploadType === 'ADJUST' && !originalUpload) {
      return true
    }

    if (!vehicle) {
      return true
    }

    if (!tuneData.ecuType || !tuneData.tuningTool) {
      return true
    }

    if (tuneData.tuningTool === 'OTHER' && !tuneData.tuningToolOther) {
      return true
    }

    if (!mileage) {
      return true
    }

    if (warrantyData.required) {
      if (
        !warrantyData.type ||
        !warrantyData.customerName ||
        !isEmail(warrantyData.customerEmail)
      ) {
        return true
      }
    }

    if (filterData.required) {
      if (
        !filterData.type ||
        !filterData.customerName ||
        !isEmail(filterData.customerEmail) ||
        !filterData.shippingAddress.line1 ||
        !filterData.shippingAddress.postcode ||
        !filterData.shippingAddress.country
      ) {
        return true
      }
    }

    if (files.length < 1) {
      return true
    }

    if (modsData.egt && !modsData.egtPCodes) {
      return true
    }

    if (modsData.egt && !egtDisclaimerAgreed) {
      return true
    }

    return false
  }, [
    uploadType,
    creditBalance,
    originalUpload,
    vehicle,
    tuneData,
    mileage,
    warrantyData,
    filterData,
    files,
    modsData,
    egtDisclaimerAgreed,
    creditsTotal,
    creditsToPurchase,
  ])

  const isLoading = setupData.isLoading || isCreatingUpload

  const handleOnUpload = (skipWarranty = false) => {
    if (isInvalid) {
      return setShowInvalid(true)
    }

    if (!skipWarranty) {
      const warrantyTwelveIndex = addons.findIndex(
        (addon) => addon.type === 'WARRANTY_12'
      )

      const warrantyTwentyFourIndex = addons.findIndex(
        (addon) => addon.type === 'WARRANTY_24'
      )

      if (warrantyTwelveIndex !== -1 || warrantyTwentyFourIndex !== -1) {
        // Warranty is available
        if (
          vehicleType === 'CAR' &&
          !addons[warrantyTwelveIndex].selected &&
          !addons[warrantyTwentyFourIndex].selected
        ) {
          // But it's not been selected
          return setWarrantyModalOpen(true)
        }
      }
    }

    setIsCreatingUpload(true)

    if (!warrantyData.required && !filterData.required) {
      setNoAddonPaymentIntentNeeded(true)
    }

    if (creditsToPurchase) {
      return creditPaymentIntentActions.sendRequest({
        url: '/billing/upload/checkout',
        method: 'post',
        data: {
          numberOfCredits: creditsToPurchase,
        },
      })
    }

    if (!allFilesUploaded) {
      startFileUpload()
    } else {
      uploadActions.sendRequest({
        url: '/uploads',
        method: 'post',
        data: {
          uploadType,
          originalUpload: originalUpload?.id,
          vehicle: vehicle.id,
          reference,
          vrm,
          mileage,
          tune: tuneData,
          mods: modsData,
          addons,
          files: files.map((file) => file.id),
        },
      })
    }
  }

  if (hasFetchSetupError) {
    return <NewUploadSetupError />
  }

  return (
    <>
      <Title
        title="New upload"
        description="Use this form to upload a file to be remapped."
      />
      <div className="max-w-5xl mx-auto px-4">
        {isFetchingSetup ? (
          <div className="flex items-center justify-center p-12 flex-1">
            <span className="spinner" />
          </div>
        ) : (
          <div className="divide-y divide-gray-200">
            <NewUploadTypeForm
              isLoading={isLoading}
              uploadType={uploadType}
              setUploadType={setUploadType}
              originalUpload={originalUpload}
              setOriginalUpload={setOriginalUpload}
              setVehicle={setVehicle}
              vehicleType={vehicleType}
              setVehicleType={setVehicleType}
              creditBalance={creditBalance}
            />
            <NewUploadVehicleForm
              isLoading={isLoading}
              vehicleType={vehicleType}
              setVehicleType={setVehicleType}
              vehicle={vehicle}
              setVehicle={setVehicle}
              mileageStr={mileageStr}
              setMileageStr={setMileageStr}
              setMileage={setMileage}
              vrm={vrm}
              setVrm={setVrm}
              reference={reference}
              setReference={setReference}
              uploadType={uploadType}
            />
            <NewUploadTuneForm
              isLoading={isLoading}
              tuneData={tuneData}
              setTuneData={setTuneData}
              modsData={modsData}
              setModsData={setModsData}
            />
            <NewUploadFileUpload
              files={files}
              addFiles={addFiles}
              removeFile={removeFile}
            />
            {availableAddons.length > 0 && paymentMethods.length > 0 && (
              <NewUploadAddonForm
                isLoading={isLoading}
                dealerAddress={auth.membership.dealer.address}
                availableAddons={availableAddons}
                vehicle={vehicle}
                mileage={mileage}
                warrantyData={warrantyData}
                setWarrantyData={setWarrantyData}
                filterData={filterData}
                setFilterData={setFilterData}
                vehicleType={vehicleType}
                priority={priority}
                setPriority={setPriority}
              />
            )}
            <NewUploadCheckout
              creditItems={creditItems}
              creditsTotal={creditsTotal}
              cardItems={cardItems}
              cardTotal={cardTotal}
              cardVatTotal={cardVatTotal}
              paymentMethods={paymentMethods}
              chosenMethodIndex={chosenMethodIndex}
              setChosenMethodIndex={setChosenMethodIndex}
              creditBalance={creditBalance}
              creditsToPurchase={creditsToPurchase}
              setCreditsToPurchase={setCreditsToPurchase}
              creditPurchaseError={creditPurchaseError}
              isMaster={isMaster}
            />
            <div>
              <div className="sm:w-2/3 sm:ml-auto sm:pl-12 pt-4 pb-8">
                {showInvalid && isInvalid && (
                  <NewUploadRequiredFields
                    creditBalance={creditBalance}
                    creditsToPurchase={creditsToPurchase}
                    creditsTotal={creditsTotal}
                    uploadType={uploadType}
                    originalUpload={originalUpload}
                    vehicle={vehicle}
                    tuneData={tuneData}
                    mileage={mileage}
                    warrantyData={warrantyData}
                    filterData={filterData}
                    files={files}
                    modsData={modsData}
                    egtDisclaimerAgreed={egtDisclaimerAgreed}
                  />
                )}
                {modsData.egt && (
                  <div className="pb-8 pl-4">
                    <div>
                      <h5 className="text-sm font-semibold">
                        EGT off disclaimer
                      </h5>
                      <p className="text-xs text-gray-600 mt-1">
                        By selecting the "EGT off" option, you accept all
                        responsibility for any mechanical failures after the
                        modified file is written in. EGT sensor delete can
                        sometimes cause erratic idle issues and engine failures
                        on certain vehicles. We accept no responsibility for any
                        issues.
                      </p>
                    </div>
                    <div className="flex items-center space-x-4 mt-4">
                      <input
                        id="egtDisclaimer"
                        className="h-5 w-5 rounded-md text-blue-600 border-gray-400"
                        type="checkbox"
                        onChange={() =>
                          setEgtDisclaimerAgreed(!egtDisclaimerAgreed)
                        }
                        checked={egtDisclaimerAgreed}
                      />
                      <label
                        className="text-sm underline"
                        htmlFor="egtDisclaimer"
                      >
                        I agree to this EGT off disclaimer
                      </label>
                    </div>
                  </div>
                )}
                <NewUploadFormActions
                  isCreatingUpload={isCreatingUpload}
                  isDisabled={isCreatingUpload}
                  handleOnUpload={handleOnUpload}
                />
              </div>
            </div>
          </div>
        )}
      </div>
      <NewUploadNoCreditsModal isOpen={noCreditsModalOpen} />
      <NewUploadNegativeCreditsModal isOpen={negativeCreditsModalOpen} />
      <NewUploadNoPaymentMethodsModal isOpen={noPaymentMethodsModalOpen} />
      <TermsModal
        isOpen={termsModalOpen}
        onAccept={handleTermsAccept}
        setIsOpen={() => history.push('/uploads')}
      />
      <WarrantyModal
        isOpen={warrantyModalOpen}
        setIsOpen={() => setWarrantyModalOpen(false)}
        onContinue={() => {
          handleOnUpload(true)
          setWarrantyModalOpen(false)
        }}
      />
    </>
  )
}

export default NewUpload
