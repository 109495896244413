import React from 'react'
import cn from 'classnames'
import { Link } from 'react-router-dom'
import { format } from 'date-fns'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faFireAlt,
  faWrench,
  faBoxFull,
} from '@fortawesome/pro-regular-svg-icons'
import {
  faInfoCircle,
  faCalendarAlt,
  faAngleRight,
  faUserCircle,
} from '@fortawesome/pro-light-svg-icons'

import { toCapitalCase } from '../../../utils/strings'
import Label from '../../Label'
import Badge from '../../Badge'
import {
  getStatus,
  getUploadLabelColor,
  getTune,
  getTuneIcon,
  getVehicleTypeIcon,
} from '../../../utils/uploads'

const UploadListItem = ({ upload, page, pathname, isAdmin, filters }) => {
  const leftCn = cn('min-w-[260px]', {
    'flex-1': !isAdmin,
    'flex-[3]': isAdmin,
  })

  const middleCn = cn('min-w-[260px]', {
    'flex-[2]': !isAdmin,
    'flex-[3]': isAdmin,
  })

  const rightCn = cn('min-w-[260px]', {
    'flex-[2]': isAdmin,
  })

  const renderVehicleReference = () => {
    if (upload.vrm) {
      return upload.vrm
    }

    if (upload.reference) {
      return upload.reference
    }

    return 'Manual'
  }

  return (
    <Link
      className="border-b-4 border-gray-100 relative hover:bg-gray-50"
      to={{
        pathname: `/uploads/view/${upload.id}`,
        state: {
          upload,
          listState: {
            page,
            pathname,
            filters,
          },
        },
      }}
    >
      <div className="flex space-x-3 pr-3 pl-7 pt-4 pb-3 relative">
        <div className={leftCn}>
          <p
            className={cn(
              'text-blue-600 text-sm mb-1.5 h-5 uppercase leading-5',
              {
                'font-medium': !upload.isUnreplied,
                'font-semibold': upload.isUnreplied,
              }
            )}
          >
            {upload.vehicleType === 'CAR' && (
              <>
                {upload.vehicle.make} {upload.vehicle.model}{' '}
                {upload.vehicle.startYear}
              </>
            )}
            {upload.vehicleType !== 'CAR' && (
              <>
                {upload.manualVehicle.make} {upload.manualVehicle.model}{' '}
                {upload.manualVehicle.year}
              </>
            )}
          </p>
          <p className="text-gray-600 text-xs">
            <FontAwesomeIcon
              className="text-gray-500 mr-2"
              icon={faInfoCircle}
              size="lg"
            />
            {renderVehicleReference()} •{' '}
            {upload.vehicleType === 'CAR' && (
              <>
                {toCapitalCase(upload.vehicle.fuel || '')} •{' '}
                {upload.vehicle.engineSize}cc
              </>
            )}
            {upload.vehicleType !== 'CAR' && (
              <>
                {upload.manualVehicle.hpModel} •{' '}
                {upload.manualVehicle.engineSize}
              </>
            )}
          </p>
        </div>
        <div className={middleCn}>
          <p
            className={cn('text-gray-900 mb-1.5 h-5 text-sm leading-5', {
              'font-medium': upload.isUnreplied,
            })}
          >
            Upload number {upload.uploadNumber}
            <Label color={getUploadLabelColor(upload.status)} className="ml-2">
              {getStatus(upload.status)}
            </Label>
          </p>
          <p className="text-gray-600 text-xs">
            <FontAwesomeIcon
              className="text-gray-500 mr-2"
              icon={faCalendarAlt}
              size="lg"
            />
            Uploaded{' '}
            {format(new Date(upload.createdAt), "dd MMMM yyy 'at' hh:mma")}
          </p>
        </div>
        {isAdmin && (
          <div className={rightCn}>
            <p
              className={cn('text-gray-900 mb-1.5 text-sm mr-4 leading-5', {
                'font-medium': upload.isUnreplied,
              })}
            >
              {upload.createdBy.dealer.name}
            </p>
            <p className="text-gray-600 text-xs">
              <FontAwesomeIcon
                className="text-gray-500 mr-2"
                icon={faUserCircle}
                size="lg"
              />
              TGT number {upload.createdBy.dealer.tgtNumber}
            </p>
          </div>
        )}
        <div>
          <FontAwesomeIcon
            className="absolute right-5 mt-1 top-1/2 -translate-y-1/2 text-gray-400"
            size="lg"
            icon={faAngleRight}
          />
        </div>
      </div>
      <div className="border-dashed border-t border-gray-200 py-2 ml-7 mr-5 flex items-center justify-start space-x-2">
        {upload.priority && (
          <Badge icon={faFireAlt} color="pink">
            Priority file
          </Badge>
        )}
        <Badge icon={getVehicleTypeIcon(upload.vehicleType)} color="gray">
          {toCapitalCase(upload.vehicleType)}
        </Badge>
        <Badge icon={getTuneIcon(upload.tune.tuneRequired)} color="gray">
          {getTune(upload.tune.tuneRequired)}
        </Badge>
        <Badge
          icon={upload.uploadType === 'FULL' ? faBoxFull : faWrench}
          color="gray"
        >
          {upload.uploadType === 'FULL' ? 'Full' : 'Adjustment'} remap
        </Badge>
      </div>
      {upload.isUnreplied && (
        <span className="absolute my-2 left-0 top-0 bottom-0 w-1 bg-blue-600 rounded-tr-md rounded-br-md" />
      )}
    </Link>
  )
}

export default UploadListItem
