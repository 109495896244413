import React, { useState } from 'react'

import Button from '../../components/Button'
import InputGroup from '../../components/InputGroup'
import { getStartLastMonth, getEndLastMonth } from '../../utils/reports'
import useLocalStorage from '../../hooks/useLocalStorage'

const API_URL = import.meta.env.VITE_API_URL
const REPORT_ROUTE = '/admin/reports/support'
const endpoint = `${API_URL}${REPORT_ROUTE}?`

const SupportListReport = () => {
  const [startDate, setStartDate] = useState(getStartLastMonth())
  const [endDate, setEndDate] = useState(getEndLastMonth())
  const [getAuthToken] = useLocalStorage('authToken')
  const jwt = getAuthToken()

  const url = `${endpoint}from=${startDate}&to=${endDate}&jwt=${jwt}`

  return (
    <div>
      <div className="flex space-x-3 mb-4">
        <InputGroup
          type="date"
          label="Created from"
          id="startDate"
          onChange={(date) => setStartDate(date)}
          value={startDate}
        />
        <InputGroup
          type="date"
          label="To"
          id="endDate"
          onChange={(date) => setEndDate(date)}
          value={endDate}
        />
      </div>
      <Button color="blue" as="a" href={url} target="_blank">
        Download CSV
      </Button>
    </div>
  )
}

export default SupportListReport
