import React, {
  useState,
  useMemo,
  useEffect,
  useCallback,
  useContext,
} from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faSearch,
  faLaptop,
  faWifiSlash,
} from '@fortawesome/pro-regular-svg-icons'
import { Link } from 'react-router-dom'

import Title from '../../Title'
import Button from '../../Button'
import BoxNav from '../../BoxNav'
import SearchInput from '../../SearchInput'
import TablePagination from '../../TablePagination'
import AuthContext from '../../../context/AuthContext'
import usePagination from '../../../hooks/usePagination'
import usePrivateSocket from '../../../hooks/usePrivateSocket'
import useObjState from '../../../hooks/useObjState'
import UploadListItem from './ListItem'
import { ON_HOLD } from '../../../utils/dealers'
import {
  FETCH_UPLOAD_LIST,
  UPLOAD_STATUS_CHANGED,
  NEW_UPLOAD,
  NEW_MESSAGE,
  NEW_FILES,
} from '../../../utils/sockets'

const UploadList = ({ location, isAdmin }) => {
  const [auth] = useContext(AuthContext)
  const [isLoading, setIsLoading] = useState(true)
  const [count, setCount] = useState(null)
  const [uploads, setUploads] = useState(null)
  const [showErr, setShowErr] = useState(false)
  const [hasSkippedFirst, setHasSkippedFirst] = useState(false)
  const [hasRestoredState, setHasRestoredState] = useState(false)

  const [filters, setFilters] = useObjState({
    search: null,
    status: null,
  })

  const [
    { page, pageSize, ...paginationValues },
    { goToFirst, goToPage, ...paginationActions },
  ] = usePagination(count, {
    page: location?.state?.page || 1,
    pageSizeKey: 'upload.list.pageSize',
  })

  const [{ inRoom, err }, { socket, resetErr }] = usePrivateSocket(
    '/upload-list',
    true
  )

  const prepareUpload = useCallback(
    (upload) => {
      if (!isAdmin && upload.status === 'DOWNLOADED') {
        return { ...upload, isUnreplied: false }
      }

      const hasMessages = upload.messages.length > 0
      const fromUser = (message) => message.createdBy.accountType === 'USER'

      const isUnreplied = isAdmin
        ? hasMessages && fromUser(upload.messages[upload.messages.length - 1])
        : hasMessages && !fromUser(upload.messages[upload.messages.length - 1])

      return { ...upload, isUnreplied }
    },
    [isAdmin]
  )

  const handleUploadChange = useCallback(
    ({ upload }) => {
      const uploadIndex = uploads.findIndex((x) => x.id === upload.id)

      if (uploadIndex > -1) {
        // The upload is currently in view
        const newUploads = [...uploads]

        if (page === 1) {
          // Move to the top of the list
          newUploads.splice(uploadIndex, 1)
          newUploads.unshift(prepareUpload(upload))
          return setUploads(newUploads)
        }

        // Keep in place so we don't distract the user
        newUploads[uploadIndex] = prepareUpload(upload)
        return setUploads(newUploads)
      }
      // The upload is not in view

      if (page === 1) {
        const newUploads = [prepareUpload(upload), ...uploads]

        if (newUploads.length > 10) {
          newUploads.pop()
        }

        return setUploads(newUploads)
      }

      // The upload is not in view and we are not on page 1
      // Don't change anything so we don't distract the user
      return
    },
    [prepareUpload, uploads, page]
  )

  const handleStatusChange = useCallback(
    (status) => {
      if (status !== filters.status) {
        setFilters({ status })

        if (page !== 1) {
          goToPage(1)
        }
      }
    },
    [setFilters, filters.status, goToPage, page]
  )

  const handleSearchChange = useCallback(
    (search) => {
      if (search !== filters.search) {
        setFilters({ search })

        if (page !== 1) {
          goToPage(1)
        }
      }
    },
    [setFilters, filters.search, goToPage, page]
  )

  useEffect(() => {
    if (hasSkippedFirst) {
      if (!hasRestoredState && location.state?.page) {
        goToPage(location.state.page)
        handleStatusChange(location.state.filters.status)
        handleSearchChange(location.state.filters.search)
      }
      setHasRestoredState(true)
    } else {
      setHasSkippedFirst(true)
    }
  }, [
    location.state,
    goToPage,
    hasRestoredState,
    handleSearchChange,
    handleStatusChange,
    hasSkippedFirst,
  ])

  useEffect(() => {
    if (
      inRoom &&
      hasRestoredState &&
      filters.status !== null &&
      filters.search !== null
    ) {
      setUploads(null)
      setIsLoading(true)

      socket.emit(
        FETCH_UPLOAD_LIST,
        { page, pageSize, filters },
        (err, { uploads, count }) => {
          setIsLoading(false)

          if (uploads) {
            setUploads(uploads.map((upload) => prepareUpload(upload)))
            setCount(count)
          } else {
            setShowErr(true)
          }
        }
      )
    }
  }, [
    inRoom,
    hasRestoredState,
    page,
    pageSize,
    socket,
    filters,
    setUploads,
    prepareUpload,
  ])

  useEffect(() => {
    if (socket) {
      socket.on(UPLOAD_STATUS_CHANGED, handleUploadChange)
      return () => socket.off(UPLOAD_STATUS_CHANGED)
    }
  }, [socket, handleUploadChange])

  useEffect(() => {
    if (socket) {
      socket.on(NEW_UPLOAD, handleUploadChange)
      return () => socket.off(NEW_UPLOAD)
    }
  }, [socket, handleUploadChange])

  useEffect(() => {
    if (socket) {
      socket.on(NEW_MESSAGE, handleUploadChange)
      return () => socket.off(NEW_MESSAGE)
    }
  }, [socket, handleUploadChange])

  useEffect(() => {
    if (socket) {
      socket.on(NEW_FILES, handleUploadChange)
      return () => socket.off(NEW_FILES)
    }
  }, [socket, handleUploadChange])

  useEffect(() => {
    if (err) {
      setShowErr(true)
      resetErr()
    }
  }, [err, resetErr])

  const boxNavLinks = useMemo(
    () => [
      { to: '/uploads/list/all', label: 'All', key: 'ALL' },
      { to: '/uploads/list/ready', label: 'Ready', key: 'READY' },
      {
        to: '/uploads/list/in-progress',
        label: 'In progress',
        key: 'IN_PROGRESS',
      },
      { to: '/uploads/list/pending', label: 'Pending', key: 'PENDING' },
      { to: '/uploads/list/on-hold', label: 'On hold', key: 'ON_HOLD' },
      {
        to: '/uploads/list/downloaded',
        label: 'Downloaded',
        key: 'DOWNLOADED',
      },
      {
        to: '/uploads/list/re-credited',
        label: 'Re-credited',
        key: 'RE_CREDITED',
      },
    ],
    []
  )

  const renderUploadList = () => {
    if (showErr) {
      return (
        <div className="flex items-center justify-center flex-1 flex-col p-18">
          <FontAwesomeIcon
            icon={faWifiSlash}
            className="text-gray-600"
            size="2x"
          />
          <h3 className="text-xl font-semibold tracking-tighter mt-4">
            Could not retrieve uploads
          </h3>
          <p className="text-sm text-gray-600 mt-1 mb-6">
            Please refresh the page to try again.
          </p>
          <Button color="white" onClick={() => window.location.reload()}>
            Refresh page
          </Button>
        </div>
      )
    }

    if (isLoading || uploads === null) {
      return (
        <div className="flex items-center justify-center p-12 flex-1">
          <span className="spinner" />
        </div>
      )
    }

    if (uploads.length < 1 && !filters.search && !isAdmin) {
      return (
        <div className="flex items-center justify-center flex-1 flex-col p-18">
          <FontAwesomeIcon
            icon={faLaptop}
            className="text-gray-600"
            size="3x"
          />
          <h3 className="text-xl font-semibold tracking-tighter mt-4">
            Upload your first ECU file
          </h3>
          <p className="text-sm text-gray-600 mt-1 mb-6">
            Click the new upload button to begin.
          </p>
          <Button as={Link} color="blue" to="/uploads/new">
            New upload
          </Button>
        </div>
      )
    }

    if (uploads.length < 1) {
      return (
        <div className="flex items-center justify-center flex-1 flex-col p-18">
          <FontAwesomeIcon
            icon={faSearch}
            className="text-gray-600"
            size="3x"
          />
          <h3 className="text-xl font-semibold tracking-tighter mt-4">
            Could not find any uploads
          </h3>
          <p className="text-sm text-gray-600 mt-1 mb-6">
            Try changing your filters or search term.
          </p>
        </div>
      )
    }

    return (
      <>
        <div className="flex flex-col flex-wrap flex-1 justify-start overflow-x-auto border-b">
          {uploads.map((upload) => (
            <UploadListItem
              page={page}
              pathname={location.pathname}
              filters={filters}
              upload={upload}
              isAdmin={isAdmin}
              key={upload.id}
            />
          ))}
        </div>
        <div className="py-1">
          <TablePagination
            {...paginationValues}
            {...paginationActions}
            count={count}
            page={page}
            pageSize={pageSize}
            goToPage={goToPage}
            goToFirst={goToFirst}
            scrollOnChange
          />
        </div>
      </>
    )
  }

  const searchPlaceholder = isAdmin
    ? 'Search by vehicle make/model, vrm, reference, upload number, dealer name or TGT number'
    : 'Search by vehicle make/model, vrm, reference or upload number'

  // prettier-ignore
  const titleBtns = isAdmin || auth.membership.dealer.status === ON_HOLD
    ? undefined
    : () => (
      <Button color="blue" as={Link} to="/uploads/new">
        New upload
      </Button>
    )

  return (
    <>
      <Title title="Uploads" renderBtns={titleBtns} />
      <div className="max-w-5xl mx-auto px-4">
        <div className="bg-white rounded-lg border border-gray-200 flex flex-col min-h-[500px] shadow-sm">
          <div className="border-b border-gray-200 rounded-tr-lg rounded-tl-lg">
            <BoxNav
              current={location.pathname}
              onChange={handleStatusChange}
              links={boxNavLinks}
            />
          </div>
          <div className="border-b border-gray-200 flex py-3 px-4">
            <SearchInput
              placeholder={searchPlaceholder}
              onChange={handleSearchChange}
              value={filters.search}
            />
          </div>
          {renderUploadList()}
        </div>
      </div>
    </>
  )
}

export default UploadList
