import React, { useMemo } from 'react'

import Title from '../../Title'
import Breadcrumbs from '../../Breadcrumbs'
import Button from '../../Button'
import Label from '../../Label'
import {
  getStatus,
  getStatusLabelColor,
  getCategory,
  OPEN,
  CLOSED,
} from '../../../utils/support'

const ViewTicketTitle = (props) => {
  const { isAdmin, ticket, setShowCloseModal, setShowOpenModal, location } =
    props

  const renderBtns = () => {
    if (ticket.status === OPEN && setShowCloseModal) {
      return (
        <Button onClick={() => setShowCloseModal(true)}>Close ticket</Button>
      )
    }

    if (ticket.status === CLOSED && setShowOpenModal) {
      return (
        <Button onClick={() => setShowOpenModal(true)}>Re-open ticket</Button>
      )
    }

    return null
  }

  const breadcrumbLinks = useMemo(() => {
    const ticketList = { label: 'Ticket list', to: '/support' }

    if (location?.state?.listState) {
      const { pathname, page, filters } = location.state.listState
      ticketList.to = { pathname, state: { page, filters } }
    }

    return [
      ticketList,
      {
        label: (
          <>
            Ticket number {ticket.ticketNumber}{' '}
            <Label color={getStatusLabelColor(ticket.status)} className="ml-2">
              {getStatus(ticket.status)}
            </Label>
          </>
        ),
      },
    ]
  }, [ticket, location])

  const getTitle = () => {
    if (isAdmin && ticket.createdBy?.dealer) {
      const { name, tgtNumber } = ticket.createdBy.dealer
      return `${name} (${tgtNumber}) - ${ticket.subject}`
    }

    if (ticket.category) {
      return `${getCategory(ticket.category) || ''} - ${ticket.subject}`
    }

    return '-'
  }

  return (
    <Title
      title={getTitle()}
      renderBreadcrumbs={() => <Breadcrumbs links={breadcrumbLinks} />}
      renderBtns={renderBtns}
    />
  )
}

export default ViewTicketTitle
