import React from 'react'

import usePrivateApi from '../../../hooks/usePrivateApi'
import Button from '../../../components/Button'
import Dialog from '../../../components/Dialog'

const AdminReactivateModal = ({ isOpen, setIsOpen, membership }) => {
  const [{ isLoading, success, error }, { sendRequest }] = usePrivateApi()

  const handleSubmit = () => {
    sendRequest({
      url: `/admin/admins/${membership.id}`,
      method: 'patch',
      data: { status: 'ACTIVE' },
    })
  }

  if (error) {
    return (
      <Dialog
        isOpen={isOpen}
        setIsOpen={() => window.location.reload()}
        title="An error occurred"
        buttonProps={null}
      >
        <p className="mt-2 text-gray-600 text-sm">
          Could not re-activate admin account. Please try again later.
        </p>
        <Button
          className="mt-4"
          color="white"
          onClick={() => window.location.reload()}
        >
          Finish and close
        </Button>
      </Dialog>
    )
  }

  if (success) {
    return (
      <Dialog
        isOpen={isOpen}
        setIsOpen={() => window.location.reload()}
        title="Account re-activated"
        buttonProps={null}
      >
        <p className="mt-2 text-gray-600 text-sm">
          Successfully re-activated{' '}
          <strong>
            {membership.user.firstName} {membership.user.lastName}'s
          </strong>{' '}
          account ({membership.user.email}).
        </p>
        <Button
          className="mt-4"
          color="white"
          onClick={() => window.location.reload()}
        >
          Finish and close
        </Button>
      </Dialog>
    )
  }

  return (
    <Dialog
      isOpen={isOpen}
      setIsOpen={() => setIsOpen(false)}
      title="Re-activate admin account"
      buttonProps={{
        disabled: isLoading,
        onClick: handleSubmit,
        children: isLoading ? 'Activating...' : 'Activate account',
        color: 'blue',
      }}
    >
      <p className="mt-2 text-gray-600 text-sm">
        Are you sure you wish to re-activate{' '}
        <strong>
          {membership.user.firstName} {membership.user.lastName}'s{' '}
        </strong>
        account?
      </p>
      <p className="mt-2 text-gray-600 text-sm">
        This admin will regain full access to the system.
      </p>
    </Dialog>
  )
}

export default AdminReactivateModal
