import React from 'react'
import { Link } from 'react-router-dom'

import Button from '../../../components/Button'
import Dialog from '../../../components/Dialog'
import ExternalLink from '../../../components/ExternalLink'
import TextButton from '../../../components/TextButton'

const CheckoutNoMethodsModal = ({ isOpen, onClick }) => {
  return (
    <Dialog isOpen={isOpen} title="Add a payment method" buttonProps={null}>
      <p className="mt-2 text-gray-600 text-sm">
        Add a payment method to your account to begin purchasing credit
        packages. We use payment provider{' '}
        <ExternalLink to="https://stripe.com/gb">Stripe</ExternalLink> to
        process payments and store card information.
      </p>
      <div className="flex items-center mt-4 space-x-4">
        <Button color="blue" onClick={onClick}>
          Add payment method
        </Button>
        <TextButton as={Link} theme="secondary" to="/dashboard">
          Go back to dashboard
        </TextButton>
      </div>
    </Dialog>
  )
}

export default CheckoutNoMethodsModal
