import React, { useState, useEffect, useContext } from 'react'
import { Link } from 'react-router-dom'
import cn from 'classnames'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faCog,
  faUserAlt,
  faSignOut,
  faTally,
} from '@fortawesome/pro-regular-svg-icons'

import Dropdown from '../Dropdown'
import AuthContext, { initialAuth } from '../../context/AuthContext'
import useLocalStorage from '../../hooks/useLocalStorage'
import usePrivateSocket from '../../hooks/usePrivateSocket'
import { FETCH_DAILY_UPLOAD_COUNT, NEW_UPLOAD } from '../../utils/sockets'
import { Notifications } from '../Notifications'

const AdminNavBar = ({ location }) => {
  const [auth, setAuth] = useContext(AuthContext)
  const [, setAuthToken] = useLocalStorage('authToken')
  const [hasFetchedFileCount, setHasFetchedFileCount] = useState(false)
  const [fileCount, setFileCount] = useState(null)
  const [{ inRoom }, { socket }] = usePrivateSocket('/admin', true)

  const { user } = auth.membership

  const links = [
    { to: '/uploads', label: 'Uploads' },
    { to: '/support', label: 'Support' },
    { to: '/dealers', label: 'Dealers' },
    { to: '/vehicle-data', label: 'Vehicle data' },
    { to: '/credits', label: 'Credits' },
    { to: '/reports', label: 'Reports' },
    { to: '/settings', label: 'Settings' },
  ]

  useEffect(() => {
    if (!hasFetchedFileCount && inRoom) {
      setHasFetchedFileCount(true)

      socket.emit(FETCH_DAILY_UPLOAD_COUNT, (err, count) => {
        setFileCount(count)
      })
    }
  }, [socket, hasFetchedFileCount, inRoom])

  useEffect(() => {
    if (socket) {
      socket.on(NEW_UPLOAD, () => {
        setFileCount(fileCount + 1)
      })

      return () => socket.off(NEW_UPLOAD)
    }
  }, [socket, fileCount])

  const getUserInitials = () => {
    const { firstName, lastName } = user
    return firstName.charAt(0).toUpperCase() + lastName.charAt(0).toUpperCase()
  }

  const handleLogout = () => {
    setAuthToken()
    setAuth(initialAuth)
  }

  if (!auth.isLoggedIn) {
    return null
  }

  return (
    <header className="bg-blue-600">
      <div className="max-w-5xl mx-auto">
        <div className="flex items-center justify-between border-b border-blue-500 pt-5 pb-4 px-4">
          <div className="flex items-center">
            <div className="hidden sm:block h-10 mr-3 w-10">
              <span className="bg-blue-300 rounded-md text-gray-900 block font-bold leading-10 text-center text-sm">
                TGT
              </span>
            </div>
            <div>
              <span className="text-white text-sm block font-semibold leading-none mb-1">
                Topgear Support (Admin)
              </span>
              <span className="text-white block text-xs leading-none">
                {user.firstName} {user.lastName}{' '}
                <span className="hidden sm:inline">({user.email})</span>
              </span>
            </div>
          </div>
          <div className="flex">
            <Notifications />
            <Dropdown
              renderButton={({ MenuButton }) =>
                user.imageUrl ? (
                  <MenuButton className="rounded-full block p-0 ml-6 h-10 w-10 focus:outline-none focus-visible:ring-2 focus-visible:ring-white">
                    <img
                      src={user.imageUrl}
                      alt="Avatar"
                      className="bg-transparent rounded-full inline-block h-10 w-10"
                    />
                  </MenuButton>
                ) : (
                  <MenuButton className="rounded-full text-sm bg-blue-300 text-gray-900 block font-bold leading-10 text-center p-0 ml-6 h-10 w-10 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-white">
                    {getUserInitials()}
                  </MenuButton>
                )
              }
              items={[
                {
                  disabled: true,
                  renderItem: () =>
                    fileCount !== null && (
                      <div className="flex w-full items-center px-2 py-2">
                        <FontAwesomeIcon
                          icon={faTally}
                          className="mr-3 w-6 h-auto text-blue-500"
                        />
                        <div>
                          <p className="text-xs text-gray-600">
                            Daily file count
                          </p>
                          <p className="text-sm text-gray-900 font-medium">
                            {fileCount}{' '}
                            {fileCount === 1
                              ? 'file uploaded'
                              : 'files uploaded'}
                          </p>
                        </div>
                      </div>
                    ),
                },
                [
                  {
                    as: Link,
                    to: '/settings/system',
                    label: 'System status',
                    icon: faCog,
                  },
                  {
                    as: Link,
                    to: '/settings/account',
                    label: 'Your profile',
                    icon: faUserAlt,
                  },
                ],
                {
                  onClick: handleLogout,
                  label: 'Sign out',
                  icon: faSignOut,
                },
              ]}
            />
          </div>
        </div>
        <div className="pt-3 pb-3 overflow-x-auto">
          <nav className="flex items-center min-h-[40px] pl-4">
            {links.map((link) => (
              <Link
                className={cn(
                  'rounded-md text-white text-sm font-medium py-2 px-3 mr-3 no-underline whitespace-nowrap hover:text-blue-200 focus:outline-none focus:ring-2 focus:ring-white',
                  {
                    'bg-blue-800 text-white': location.pathname.startsWith(
                      link.to
                    ),
                  }
                )}
                key={link.to}
                to={link.to}
              >
                {link.label}
              </Link>
            ))}
            <div>
              <a
                className="rounded-md text-white text-sm font-medium py-2 px-3 mr-4 no-underline whitespace-nowrap hover:text-blue-200 focus:outline-none focus:ring-2 focus:ring-white"
                href="http://topgear-tuning.parts"
                target="_blank"
                rel="noopener noreferrer"
              >
                Parts store
              </a>
            </div>
          </nav>
        </div>
      </div>
    </header>
  )
}

export default AdminNavBar
