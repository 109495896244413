import React, { useEffect, useState, useMemo } from 'react'

import history from '../history'
import usePublicApi from '../hooks/usePublicApi'
import AuthTemplate from '../templates/AuthTemplate'
import Button from '../components/Button'
import InputGroup from '../components/InputGroup'

const NewPassword = () => {
  const [password, setPassword] = useState('')
  const [passwordError, setPasswordError] = useState(null)
  const [passwordConf, setPasswordConf] = useState('')
  const [passwordConfError, setPasswordConfError] = useState(null)
  const [validatedToken, setValidatedToken] = useState(null)
  const [generalError, setGeneralError] = useState(false)

  const [token, tokenType] = useMemo(() => {
    const params = new URLSearchParams(document.location.search.substring(1))
    return [params.get('t'), params.get('u')]
  }, [])

  const [
    { res, isLoading, error, success },
    { sendRequest, resetError, resetSuccess },
  ] = usePublicApi()

  useEffect(() => {
    sendRequest({
      method: 'post',
      url: '/auth/new-password-token',
      data: { token },
    })
  }, [token, sendRequest])

  useEffect(() => {
    if (success && !validatedToken) {
      setValidatedToken(token)
      resetSuccess()
    }
  }, [token, success, validatedToken, resetSuccess, res])

  useEffect(() => {
    if (success && validatedToken) {
      history.push({
        pathname: '/login',
        state: {
          passwordReset: tokenType === 'PASSWORD_RESET',
          invitedUser: tokenType === 'INVITED_USER',
        },
      })
    }
  })

  useEffect(() => {
    if (error) {
      if (error && validatedToken) {
        if (error.response) {
          if (error.response.data) {
            history.push({
              pathname: '/login',
              state: {
                passwordResetExpired: tokenType === 'PASSWORD_RESET',
                invitedUserExpired: tokenType === 'INVITED_USER',
              },
            })
          } else {
            setGeneralError(`Error code [${error.response.status}].`)
          }
        } else if (error.request) {
          setGeneralError('Please check your internet connection.')
        } else {
          setGeneralError('Error code [900].')
        }
      } else {
        history.push({
          pathname: '/login',
          state: { newPasswordExpired: true },
        })
      }
      resetError()
    }
  }, [validatedToken, error, resetError, tokenType])

  const handlePasswordChange = (e) => {
    setPassword(e.target.value)
    setPasswordError(null)
    setPasswordConfError(null)
  }

  const handlePasswordConfChange = (e) => {
    setPasswordConf(e.target.value)
    setPasswordConfError(null)
  }

  const handleSubmit = (e) => {
    e.preventDefault()

    if (password.length < 8) {
      setPasswordError('Password must be at least 8 characters.')
    } else if (password !== passwordConf) {
      setPasswordConfError('Your passwords do not match.')
    } else {
      sendRequest({
        method: 'post',
        url: '/auth/new-password',
        data: { password, token: validatedToken },
      })
    }
  }

  return (
    <AuthTemplate title="Choose new password" alertError={generalError}>
      {tokenType === 'INVITED_USER' && (
        <p className="text-gray-600 text-sm">
          Enter a password below to accept your account invitation.
        </p>
      )}
      <form onSubmit={handleSubmit}>
        <div className="mt-3 space-y-3">
          <InputGroup
            autoFocus
            autoCapitalize="none"
            autoComplete="none"
            autoCorrect="none"
            block
            label="New password"
            error={passwordError}
            disabled={isLoading}
            id="password"
            onChange={handlePasswordChange}
            help="At least 8 characters long"
            type="password"
            value={password}
          />
          <InputGroup
            autoCapitalize="none"
            autoComplete="none"
            autoCorrect="none"
            block
            error={passwordConfError}
            label="Confirm password"
            disabled={isLoading}
            id="password-confirm"
            onChange={handlePasswordConfChange}
            type="password"
            value={passwordConf}
          />
        </div>
        <Button
          block
          color="blue"
          className="mt-6 mb-3"
          disabled={isLoading}
          type="submit"
        >
          {isLoading ? 'Saving...' : 'Save new password'}
        </Button>
      </form>
    </AuthTemplate>
  )
}

export default NewPassword
