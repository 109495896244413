import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
import _ from 'lodash'

import Button from '../../Button'
import history from '../../../history'
import Title from '../../Title'

const NewUploadSuccess = ({ location }) => {
  const upload = _.get(location, 'state.upload', {})

  useEffect(() => {
    if (!upload) {
      history.push('/dashboard')
    }
  })

  return (
    <>
      <Title title="Upload created" />
      <div className="max-w-5xl mx-auto px-4">
        <h3 className="text-xl font-semibold tracking-tighter mt-4">
          Your upload number is {upload.uploadNumber}
        </h3>
        <p className="text-sm text-gray-600 mt-2 mb-6">
          We will begin work on your upload shortly.
        </p>
        <div className="mt-6 space-x-4">
          <Button
            as={Link}
            color="blue"
            to={{
              pathname: `/uploads/view/${upload.id}`,
              state: { upload },
            }}
          >
            View upload
          </Button>
          <Button color="white" as={Link} to="/uploads/list/all">
            View upload list
          </Button>
        </div>
      </div>
    </>
  )
}

export default NewUploadSuccess
