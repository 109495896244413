import React, { useMemo } from 'react'
import {
  faFireAlt,
  faBoxFull,
  faWrench,
} from '@fortawesome/pro-regular-svg-icons'

import Title from '../../Title'
import Breadcrumbs from '../../Breadcrumbs'
import Label from '../../Label'
import Badge from '../../Badge'
import { toCapitalCase } from '../../../utils/strings'
import {
  getStatus,
  getUploadLabelColor,
  getTune,
  getTuneIcon,
  getVehicleTypeIcon,
} from '../../../utils/uploads'

const ViewUploadTitle = ({ isAdmin, upload, location }) => {
  const breadcrumbLinks = useMemo(() => {
    const uploadList = { label: 'Upload list', to: '/uploads' }

    if (location?.state?.listState) {
      const { pathname, page, filters } = location.state.listState
      uploadList.to = { pathname, state: { page, filters } }
    }

    return [
      uploadList,
      {
        label: (
          <>
            Upload number {upload.uploadNumber}{' '}
            <Label color={getUploadLabelColor(upload.status)} className="ml-2">
              {getStatus(upload.status)}
            </Label>
          </>
        ),
      },
    ]
  }, [upload, location])

  const getTitle = () => {
    const { vrm, vehicle, manualVehicle, vehicleType } = upload

    if (vehicleType === 'CAR') {
      const { make, model, startYear } = vehicle

      if (isAdmin && make && upload.createdBy?.dealer) {
        const { name, tgtNumber } = upload.createdBy.dealer
        return `${name} (${tgtNumber}) - ${make} ${model} ${startYear}`
      }

      // If VRM lookup vehicle, show VRM
      if (vrm) {
        return `${make} ${model} ${startYear} - ${vrm}`
      }

      // We may not have fetched our vehicle yet
      if (make) {
        return `${make} ${model} ${startYear}`
      }
    }

    if (vehicleType && vehicleType !== 'CAR') {
      const { make, model, year } = manualVehicle

      if (isAdmin && upload.createdBy?.dealer) {
        const { name, tgtNumber } = upload.createdBy.dealer

        return `${name} (${tgtNumber}) - ${make.toUpperCase()} ${model.toUpperCase()} ${year}`
      }

      return `${make.toUpperCase()} ${model.toUpperCase()} ${year}`
    }

    return '-'
  }

  return (
    <Title
      title={getTitle()}
      renderBreadcrumbs={() => <Breadcrumbs links={breadcrumbLinks} />}
    >
      <div className="mt-3 flex items-center justify-start space-x-2">
        {upload.priority && (
          <Badge icon={faFireAlt} color="pink">
            Priority file
          </Badge>
        )}
        <Badge icon={getVehicleTypeIcon(upload.vehicleType)} color="gray">
          {toCapitalCase(upload.vehicleType)}
        </Badge>
        <Badge icon={getTuneIcon(upload.tune.tuneRequired)} color="gray">
          {getTune(upload.tune.tuneRequired)}
        </Badge>
        <Badge
          icon={upload.uploadType === 'FULL' ? faBoxFull : faWrench}
          color="gray"
        >
          {upload.uploadType === 'FULL' ? 'Full' : 'Adjustment'} remap
        </Badge>
      </div>
    </Title>
  )
}

export default ViewUploadTitle
