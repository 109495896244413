import React from 'react'

import { getWarrantyOptions } from '../../../../utils/uploads'
import CheckBox from '../../../CheckBox'
import InputGroup from '../../../InputGroup'

const NewUploadAddonWarranty = (props) => {
  const { isLoading, warrantyData, setWarrantyData } = props

  return (
    <CheckBox
      checked={warrantyData.required}
      disabled={isLoading}
      hideDetails={!warrantyData.required}
      description="We recommend you offer mechanical warranty to your customers (UK and IE only)."
      onChange={() => setWarrantyData({ required: !warrantyData.required })}
      renderContent={() => (
        <div className="space-y-4">
          <InputGroup
            required
            type="select"
            label="Warranty required"
            id="warrantyAmount"
            disabled={isLoading}
            onChange={(e) => setWarrantyData({ type: e.target.value })}
            value={warrantyData.type}
            renderOptions={getWarrantyOptions}
          />
          <div className="space-y-4 lg:space-y-0 lg:flex lg:flex-1 lg:space-x-3">
            <InputGroup
              required
              className="flex-1"
              label="Customer name"
              placeholder="Enter customer's name"
              id="warrantyName"
              disabled={isLoading}
              onChange={(e) =>
                setWarrantyData({ customerName: e.target.value })
              }
              value={warrantyData.customerName}
            />
            <InputGroup
              required
              className="flex-1"
              label="Customer email"
              placeholder="Enter customer's email"
              id="warrantyEmail"
              disabled={isLoading}
              onChange={(e) =>
                setWarrantyData({ customerEmail: e.target.value })
              }
              value={warrantyData.customerEmail}
            />
          </div>
          <div>
            <p className="text-uppercase text-xs font-semibold uppercase">
              Important!
            </p>
            <p className="text-xs mt-1 text-gray-600">
              We will email your customer directly with their warranty
              information once your file is ready for download. If your upload
              is re-credited for whatever reason we will refund your warranty
              payment and the warranty will be void. Refunds may take 2-5
              working days from the date your upload is re-credited.
            </p>
          </div>
        </div>
      )}
      label={
        <>
          Mechanical warranty (from £10.00){' '}
          <span className="bg-blue-100 py-0.5 px-1.5 align-baseline rounded-md text-blue-700 ml-1 text-xs font-semibold tracking-tight">
            Recommended
          </span>
        </>
      }
    />
  )
}

export default NewUploadAddonWarranty
