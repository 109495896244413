import React from 'react'

const Disclaimer = () => {
  return (
    <div>
      <h4 className="text-base font-medium mb-4">Disclaimer</h4>
      <p className="text-gray-600 text-sm mb-2">
        Please remember that every vehicle is unique; Manufacturers will
        sometimes put different ECUs into the same car, and many vehicles have
        unusual configurations or modifications which are only apparent when
        reading the ECU. As such, we recommend that you discuss the potential
        gains and tuning options available directly with your chosen tuning
        technician.
      </p>
      <p className="text-gray-600 text-sm mb-2">
        Topgear Tuning is incredibly proud of the data it has developed over
        many years, both in-house and in conjunction with the world’s best
        tuning and modifications companies. The data is constantly being
        refined, amended, updated and expanded. Topgear Tuning is confident that
        its data is the most comprehensive ECU tuning and remapping data
        currently available anywhere in the world.
      </p>
      <p className="text-gray-600 text-sm mb-2">
        Whilst every effort is made to ensure the data in above table is
        up-to-date and accurate, it should only be used as a guide. It is
        possible that the economy and performance gains available are in excess
        of those stated above, conversely actual gains may be less than the
        current data suggests.
      </p>
    </div>
  )
}

export default Disclaimer
