import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCreditCard } from '@fortawesome/pro-regular-svg-icons'
import { Link } from 'react-router-dom'

import Button from '../../Button'
import TextButton from '../../TextButton'
import Dialog from '../../Dialog'

const NewUploadNoPaymentMethodsModal = ({ isOpen }) => {
  return (
    <Dialog
      isOpen={isOpen}
      title="Unable to create new upload"
      buttonProps={null}
    >
      <div className="mt-4 sm:flex sm:items-start sm:space-x-3">
        <div className="bg-blue-100 rounded-md w-10 h-10 flex flex-shrink-0 items-center justify-center">
          <FontAwesomeIcon
            icon={faCreditCard}
            className="text-blue-600"
            size="lg"
          />
        </div>
        <div className="mt-2 sm:mt-0">
          <h3 className="font-semibold tracking-tight mb-1">
            Payment method required
          </h3>
          <p className="text-sm text-gray-600">
            You must add a payment method to your account before you can create
            an upload,
          </p>
          <div className="mt-4 space-y-3 sm:space-y-0 sm:flex sm:space-x-4 sm:items-center">
            <Button as={Link} color="blue" to="/settings/billing">
              Add payment method
            </Button>
            <TextButton as={Link} theme="secondary" to="/uploads">
              Go back to uploads
            </TextButton>
          </div>
        </div>
      </div>
    </Dialog>
  )
}

export default NewUploadNoPaymentMethodsModal
